import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { ServiceCall } from '../../utility/service-call';
import { constants } from '../../utility/constants';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';
import styles from '../../styles/appointmentScreensStyle';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Header from '../../components/Header';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { times } from 'lodash/fp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Ls } from '../../utility/utility';
import { PatientpageContext } from './../../context/GlobalContext';
import Logo from '../../static/images/rxone-logo.png';
import ProfileForm from '../../components/ProfileForm';
import PhoneIcon from '@mui/icons-material/Phone';
import { OPD } from '../../calls/api';
const PharmacySelection = () => {
  const [pharmacyData, setPharmacyData] = useState([]);
  const history = useHistory();
  const [loading, setLoding] = useState(false);
  const patientContext = useContext(PatientpageContext);

  const fetchData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');
    try {
      const orderData = await ServiceCall.getv2(
        OPD?.listDoctors,
        `/pharmacy/${constants.hospitalId('hosp_id')}?lang=${langParam ? langParam : 'en'}`
      );
      if (orderData.status === 200) {
        setPharmacyData(orderData.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

 
  const handlebuttonclick = () => {
    setLoding(true);
    history.push({
      pathname: `/`,
      search: history?.location?.search,
    });
  };

  useEffect(() => {
    if (Ls.get('pt_key') !== null && Ls.get('pt_token') !== null) {
      if (!patientContext.hospitalLoader) {
        patientContext.getProfile();
        // patientContext.getAppointment();
        // patientContext.getRecords();
        // patientContext.getReceipts();
      }
    }
  }, [patientContext.hospitalLoader]);

  return (
    <>
      <Header page="login"></Header>
      {patientContext?.profileForm?.enable ? (
        <div className="details__page">
          <div className="details__page_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="details__page_profile">
            <ProfileForm />
          </div>
        </div>
      ) : (
        <div className="pharmacy-selection">
          <h2>
            <button onClick={handlebuttonclick}>
              <ChevronLeftIcon />
            </button>
          </h2>

          <Grid container spacing={2} direction="column">
            {pharmacyData.message && (
              <div className="msg">{pharmacyData.message}</div>
            )}
            <div className="connecting-card">
              {pharmacyData.records &&
                pharmacyData.records.map((pharmacy) => (
                  <div key={pharmacy.ph_id} className="pharmacy-button">
                    <Button
                      variant="outlined"
                     
                    >
                      <div className="heading-col-pharmacy">
                        <div className="pharmacy-img">
                          <figure>
                            <img
                              src={pharmacy.brand_logo_url}
                              alt={pharmacy.ph_name}
                            />
                          </figure>
                        </div>

                        <div className="pharmacy-title">
                          <h2>{pharmacy.ph_name}</h2>

                          <a
                            style={{ color: '#333' }}
                            target="_blank"
                            href={pharmacy.geo_location}
                          >
                            <div className="time">
                              <span>
                                <TimerIcon /> {pharmacy.start_time}{' '}
                              </span>
                              <span>
                                <TimerOffIcon /> {pharmacy.end_time}
                              </span>
                            </div>
                          </a>
                        </div>
                        <div className="pharmacy-address">
                          
                          <div className="phn">
                            
                          <PhoneIcon sx={{
                            fontSize: '16px',
                            marginRight: '1.4rem',
                          }}/>
                            
                            {pharmacy.phone1}</div>
                          <AddLocationIcon
                            sx={{
                              color: '#e62625',
                              fontSize: '16px',
                              marginRight: '0.4rem',
                            }}
                          />
                          {pharmacy.address}
                        </div>
                      </div>
                    </Button>
                  </div>
                ))}
            </div>
          </Grid>
        </div>
      )}
      <Footer />
    </>
  );
};

export default PharmacySelection;
