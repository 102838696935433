import { useState, useEffect, useContext } from 'react';
import { Container } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styles from './../../styles/appointmentScreensStyle';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import { Ls } from './../../utility/utility';
import { Skeleton } from '@mui/material';
import Logo from '../../static/images/rxone-logo.png';
import DetailsTabs from '../../components/DetailsTabs';
import { Box } from '@mui/system';
import { PatientpageContext } from './../../context/GlobalContext';
import Footer from '../../components/Footer';
import ProfileForm from '../../components/ProfileForm';
// import Home from './Home';
const useStyles = makeStyles(styles);

const doctorsListDefault = {};
export default function DetailsPage(props) {
  const patientContext = useContext(PatientpageContext);
  const classes = useStyles();
  let history = useHistory();
  const { search } = useLocation();
  // const [appointmentLoader, setAppointmentLoader] = useState('loading');
  const [doctorsList, setDoctorsList] = useState(doctorsListDefault);
  const [appointmentList, setAppointmentList] = useState([]);
  const [toggleAppointmentLists, setToggleAppointmentLists] = useState(false);

  useEffect(() => {
    if (Ls.get('pt_key') !== null && Ls.get('pt_token') !== null) {
      if (!patientContext.hospitalLoader) {
        patientContext.getProfile();
        patientContext.getAppointment();
        // patientContext.getRecords();
        // patientContext.getReceipts();
      }
    } else if (!Ls.get('pt_key') || !Ls.get('pt_token')) {
      //     // If the user is not logged in, call getAppointment
      patientContext.getAppointment();
    }
  }, [patientContext.hospitalLoader]);
  var dynamicWidth = 'calc(100vh - 100px)';
  return (
    <>
      <Header pageTitle={props.pageTitle} page="details" />
      {patientContext?.profileForm?.enable ? (
        <div className="details__page">
          <div className="details__page_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="details__page_profile">
            <ProfileForm />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
          <div>
            <Box sx={{ pb: 2 }}></Box>
            {patientContext.appointmentLoader === 'loading' ? (
              <>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />

                {/* For other variants, adjust the size with `width` and `height` */}
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginTop: '6rem' }}
                >
                  <Skeleton variant="rectangular" width={300} height={60} />
                  <Skeleton variant="rounded" width={300} height={60} />
                </div>
                <div className="d-flex flex-column align-items-center mt-5">
                  <Skeleton variant="rectangular" width={300} height={60} />
                  <Skeleton variant="rounded" width={300} height={60} />
                </div>
              </>
            ) : (
              <DetailsTabs
                classes={classes}
                loader={patientContext.appointmentLoader}
                history={history}
                doctorsList={doctorsList}
                appointmentList={appointmentList}
                toggleAppointmentLists={toggleAppointmentLists}
              />
            )}
          </div>
          <Footer />

        </div>
      )}
    </>
  );
}
