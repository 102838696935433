import { useState, useEffect, useContext } from 'react';
import { PatientpageContext } from '../../context/GlobalContext';
import { Ls } from '../../utility/utility';
import { stringTrim } from '../../utility/utility';
import Button from '../../Tools/Button';
import AppointmentScreenNew from '../../components/AppointmentScreenNew';
import PersonIcon from '@mui/icons-material/Person';
import { OPD } from '../../calls/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ProfileForm from '../../components/ProfileForm';
import CircularProgress from '@mui/material/CircularProgress';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useHistory, useLocation } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import AppointmentBook from '../../components/AppointmentBook';
import CloseIcon from '@mui/icons-material/Close';
import CheckoutScreenNew from '../../components/CheckoutScreenNew';

const Patientpro = ({ location, onhandleclik }) => {
  const {
    loginMeetNow,
    setLoginMeetNow,
    setCurrSlide,
    appointmentSlots,
    setAppointment,
    appointmentData,
    redirectFrom,
    isOnline,
    isWalkin,
    toggleAppointment,
  } = location.state || {};

  const history = useHistory('');
  const locationNew = useLocation();
  const patientContext = useContext(PatientpageContext);
  const isLoggedIn = () => {
    return Ls.get('pt_key') && Ls.get('pt_token');
  };

  const [showProfileForm, setProfileForm] = useState({
    form: false,
    type: 'new',
  });

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showCheckoutScreen, setShowCheckoutScreen] = useState(false);

  const [showAppointmentScreen, setShowAppointmentScreen] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);


  // useEffect(() => {
  //   // Check if there is no redirection and a profile is selected, then open AppointmentScreenNew
  //   if (!redirectFrom && showAppointmentScreen && patientContext?.slectedAppointment?.user) {
  //     setShowAppointmentScreen(true);
  //     patientContext?.checkNextAvailableSlot();

  //   }
  // }, [redirectFrom, showAppointmentScreen, patientContext?.slectedAppointment?.user]);

  
  return (
    <>
      <Header page="login" />

      { showAppointmentScreen ? (
        // && redirectFrom === 'consultation&btn=meet-now'
        <AppointmentScreenNew
          loginMeetNow={loginMeetNow}
          setLoginMeetNow={setLoginMeetNow}
        />
      ) : loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : showProfileForm?.form ? (
        <ProfileForm
          showProfileForm={showProfileForm}
          setProfileForm={setProfileForm}
          heading=" "
        />
      ) : (
        <div className="appoint__patient">
          <button onClick={() => history.goBack()} className="styleb">
            <CloseIcon />
          </button>

          {isLoggedIn() && (
            <>
              <h6>Double Click to select profile</h6>

              <div className="appointment__patient__detail">
                <div className="appointment__patient__btn">
                  {patientContext?.profileData?.profiles?.map((list) => (
                    <div
                      className={`${
                        patientContext?.slectedAppointment?.user?.name ===
                        list?.name
                          ? 'appointment__patient__button__selected'
                          : ''
                      } appointment__patient__button`}
                      onClick={() => {
                        if (patientContext?.slectedAppointment?.user === list) {
                          // console.log(66666666, patientContext?.slectedAppointment?.user, list)
                          setShowAppointmentScreen(true);
                          setShowAppointment(true);
                          
                          history.push({
                            pathname: '/consultation',
                            search: history?.location?.search,
                            state: {
                              currentSlide: true
                            }
                          })                      
                  
                        } else {
                          // console.log(66666666, patientContext?.slectedAppointment?.user, list)
                          patientContext?.setSelectedAppointment({
                            ...patientContext?.slectedAppointment,
                            user: list,
                          });

                        }
                      }}
                    >
                      <div className="heading-col-pathology">
                        <div key={list.id} className="patient-img">
                          {list.profile_type === 'Self' ? (
                            <figure>
                              <PersonIcon style={{ fontSize: '4rem' }} />
                            </figure>
                          ) : list.profile_type === 'Family' ? (
                            <figure>
                              <GroupsIcon style={{ fontSize: '4rem' }} />
                            </figure>
                          ) : (
                            <figure>
                              <PersonIcon />
                            </figure>
                          )}
                          <div className="profile">({list.profile_type})</div>
                        </div>
                        <div className="patient-info">
                          <div className="patient-title">
                            <h2>{stringTrim(list?.name)}</h2>
                            <p>
                              ({list.age || ''}
                              Y, {list.gender})
                            </p>
                            <p>{list.phone || ''}</p>
                            <p>{list.email || ''}</p>
                          </div>
                        </div>
                        <div className="patient-actions">
                          <div className="icon-wrapper">
                            <BorderColorIcon
                              style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                              onClick={() => {
                                setLoading(true);
                                setTimeout(() => {
                                  setProfileForm({
                                    form: true,
                                    type: 'update',
                                  });
                                  setLoading(false);
                                }, 4000);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="appointment__patient__button appointment__patient__add"
                  onClick={() => {
                    setProfileForm({ form: true, type: 'new' });
                  }}
                >
                  Add +
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <Footer />
    </>
  );
};
export default Patientpro;
