import React from 'react';

const Button = ({
  children,
  containerClass,
  loader = false,
  handleClick,
  disabled= false,
  props,
}) => {
  return (
    <button
      className={`${containerClass}_button ${
        loader ? 'static_disable' : ''
      } static_button`}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
