import React from 'react'

const MemoSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.683 0c2.583 0 4.683 2.1 4.683 4.683 0 1.218-.467 2.33-1.232 3.163L9.639 9.35a.36.36 0 11-.51.51L7.606 8.34a4.66 4.66 0 01-2.923 1.026A4.689 4.689 0 010 4.683 4.688 4.688 0 014.683 0zm0 .721A3.966 3.966 0 00.721 4.683a3.967 3.967 0 003.962 3.962 3.966 3.966 0 003.962-3.962A3.966 3.966 0 004.683.72z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MemoSearch