import axios from "axios";
import { blogUrl ,baseUrl, constants } from "./constants";

const urlPath = `${baseUrl}${constants.testPath("mode") !== 'test' ? '' : '/' + constants.testPath("mode")}`;
const mode =  constants.testPath('mode');

export class ServiceCall {
  static get(url, headers) {
    return axios.get(`${baseUrl}${url}${mode}`, { headers });
  }

  static blog(url, headers) {
    return axios.get(`${blogUrl}${url}`, { headers });
  }

  static post(url, options, headers) {
    return axios.post(`${urlPath}${url}`, options, { headers });
  }
  static put(url, options, headers) {
    return axios.put(`${urlPath}${url}`, options, { headers });
  }
  static delete(url, headers) {
    return axios.delete(`${urlPath}${url}`, { headers });
  }

  static getv2(url, id, headers) {
    return axios.get(`${baseUrl}${url}${mode}${id}`, { headers });
  }
  // static getnewv2(url) {
  //   return axios.get(`${urlPath}${url});
  // }

static getnewv2(url){
  return axios.get(`${baseUrl}${url}`);
}

  static postv2(url, id, options, headers) {
    return axios.post(`${baseUrl}${url}${mode}${id}`, options, { headers });
  }
  static putv2(url, id, options, headers) {
    return axios.put(`${baseUrl}${url}${mode}${id}`, options, { headers });
  }
  static deletev2(url, id, headers) {
    return axios.delete(`${baseUrl}${url}${mode}${id}`, { headers });
  }
}

export const apiCall = (options, filterEnv) => {
  const modifiedUrl = {
    ...options,
    url: `${baseUrl}${options.url}${mode}${
      options.docID
    }`,
  };
  return axios(modifiedUrl);
};

export const apiCallOld = (options) => {
  const modifiedUrl = { ...options, url: `${urlPath}${options.url}` };
  return axios(modifiedUrl);
};
