import { createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
const checkColor = (style, subcat) => {
  // console.log('style ', style);
  return (props) => {
    return props.variant === 'contained' ? style[props.color][subcat] : {};
    // : {};
  };
};

export const theme = createTheme({
  palette: {
    primary: {
      main: green[700],
      contrastText: '#fff',
      hoverColor: green[50],
    },
    success: {
      main: green[700],
      contrastText: '#ffffff',
    },
    // secondary: green[300],
    contrastText: '#fff',
    error: {
      main: '#ff0000',
    },
    // greyBg: grey[500],
  },
});
// console.log(theme);
// theme.overrides = {
//   MuiButton: {
//     root: {
//       color: checkColor(theme.palette, 'contrastText'),
//       backgroundColor: checkColor(theme.palette, 'main'),
//       '&:hover': {
//         backgroundColor: checkColor(theme.palette),
//       },
//     },
//   },
// };
