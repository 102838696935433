import React from 'react'

const MemoDiscount = () => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33914 14L16.1527 8.00002M3.64868 13.66L1.92258 12.14C1.21851 11.52 1.21851 10.5 1.92258 9.88002L3.64868 8.36002C3.94394 8.10002 4.18241 7.59002 4.18241 7.23002V5.08002C4.18241 4.20002 5.00004 3.48002 5.99936 3.48002H8.44089C8.8497 3.48002 9.42885 3.27002 9.72411 3.01002L11.4502 1.49002C12.1543 0.870024 13.3126 0.870024 14.0167 1.49002L15.7428 3.01002C16.038 3.27002 16.6172 3.48002 17.026 3.48002H19.4675C20.4668 3.48002 21.2844 4.20002 21.2844 5.08002V7.23002C21.2844 7.59002 21.5229 8.10002 21.8182 8.36002L23.5443 9.88002C24.2483 10.5 24.2483 11.52 23.5443 12.14L21.8182 13.66C21.5229 13.92 21.2844 14.43 21.2844 14.79V16.94C21.2844 17.82 20.4668 18.54 19.4675 18.54H17.026C16.6172 18.54 16.038 18.75 15.7428 19.01L14.0167 20.53C13.3126 21.15 12.1543 21.15 11.4502 20.53L9.72411 19.01C9.42885 18.75 8.8497 18.54 8.44089 18.54H5.99936C5.00004 18.54 4.18241 17.82 4.18241 16.94V14.79C4.18241 14.42 3.94394 13.91 3.64868 13.66V13.66Z"
        stroke="#d6a725"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5791 13.5H15.5893M9.90112 8.5H9.91021"
        stroke="#d6a725"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MemoDiscount