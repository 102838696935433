import React, { useState, useContext, useEffect } from 'react';
import { AppointmentType } from '../utility/config';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { PatientpageContext } from '../context/GlobalContext';
import dayjs from 'dayjs';
import { profileIcon } from '../static/ImageManager';
import { ServiceCall } from '../utility/service-call';
import TimeSelectedScreen from './TimeSelectedScreen';
import WbCloudyOutlinedIcon from '@mui/icons-material/WbCloudyOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import styles from '../styles/appointmentScreensStyle';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Tools/Button';
import { OPD } from '../calls/api';
import { Ls } from '../utility/utility';
import { constants } from '../utility/constants';

const AppointmentBook1 = ({ setCurrSlide }) => {
  const patientContext = useContext(PatientpageContext);
  const history = useHistory();
  const useStyles = makeStyles(styles, { index: 1 });
  const classes = useStyles();
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  useEffect(() => {
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      pickedSlot: '',
    });
    getAvailableSlots();
  }, [
    patientContext?.slectedAppointment?.appointment_type,
    patientContext?.slectedAppointment?.appointment_date,
  ]);
  const followUpDate = dayjs(
    patientContext?.slectedAppointment?.nextslot?.followup_by
  );
  const currentDate = dayjs();

  const hiddeBookButton =
    !!patientContext?.slectedAppointment?.pickedSlot?.slot &&
    !!patientContext?.slectedAppointment?.appointment_type &&
    !!patientContext?.slectedAppointment?.appointment_date;

  const getAvailableSlots = async () => {
    setAppointmentSlots([]);
    let url = `/${Ls.get('hosp_id')}/${
      patientContext?.slectedAppointment?.doc?.doc_id
    }/${patientContext?.slectedAppointment?.appointment_type}/${dayjs(
      patientContext?.slectedAppointment?.appointment_date
    ).format('YYYY-MM-DD')}`;
    try {
      let availableSlots = await ServiceCall.getv2(OPD?.availableDoctor, url, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (availableSlots) {
        setAppointmentSlots(availableSlots?.data);
      }
    } catch (err) {}
  };

  const handleBookAppointment = () => {
    console.log(4444444, " RUnnugnudgdsfg")
    let url = `/${patientContext?.slectedAppointment?.nextslot?.followup_appointment_id}`;
 
    let data = {
      appointment_date: dayjs(
        patientContext?.slectedAppointment?.appointment_date
      )?.format('YYYY-MM-DD'),
      time_alloted: patientContext?.slectedAppointment?.pickedSlot?.slot,
      is_inperson:
        patientContext?.slectedAppointment?.appointment_type !==
        AppointmentType?.online
          ? true
          : false,
      slot_duration:
        patientContext?.slectedAppointment?.pickedSlot?.slot_duration,
      end_time: patientContext?.slectedAppointment?.pickedSlot?.end_time,
      // is_walkin_appointment: false,
    };
    data.end_time = data.end_time.replace(/:/g, '.');
  data.time_alloted = data.time_alloted.replace(/:/g, '.');
    patientContext
      ?.initiateAppointment(OPD?.bookfollowup, url, data)
      .then(() => {
       
          history.push({
            pathname: '/order-placed',
            search: history?.location?.search,
          });
        
      });
  };

  const handleChange = (value) => {
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      pickedSlot: value,
    });
  };
  return (
    <div className="appointment__screen__book">
      <div className="appointment__screen__book__type">
        <div className="appointment__screen__book__type__text">Select Type</div>
        <div className="appointment__screen__book__type__box">
          <div
            className={`appointment__patient__button ${
              patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.online
                ? 'appointment__patient__button__selected'
                : ''
            }`}
            onClick={() => {
              patientContext?.setSelectedAppointment({
                ...patientContext?.slectedAppointment,
                appointment_type: AppointmentType?.online,
              });
            }}
          >
            Online (Video)
          </div>
          <div
            className={`appointment__patient__button ${
              patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.inperson
                ? 'appointment__patient__button__selected'
                : ''
            }`}
            onClick={() => {
              patientContext?.setSelectedAppointment({
                ...patientContext?.slectedAppointment,
                appointment_type: AppointmentType?.inperson,
              });
            }}
          >
            In-Person (at Facility)
          </div>
        </div>
      </div>
      <div className="appointment__screen__book__date">
        <div className="appointment__screen__book__type__text">Select Date</div>
        <div className="appointment__screen__book__date__input">
          <label htmlFor="appointment-date">
            {patientContext?.slectedAppointment?.nextslot?.followup_by}
          </label>
          <input
            id="appointment-date"
            type="date"
            name="appointment_date"
            value={patientContext?.slectedAppointment?.appointment_date || ''}
            min={currentDate.format('YYYY-MM-DD')}
            max={followUpDate.format('YYYY-MM-DD')}
            onChange={(e) => {
              if (e.target.value) {
                patientContext?.setSelectedAppointment({
                  ...patientContext?.slectedAppointment,
                  appointment_date: dayjs(e.target.value).format(
                    'ddd MMM DD YYYY'
                  ),
                });
              }
            }}
          />

          <img src={profileIcon?.calendar} alt="profile-name-icon" />
        </div>
      </div>
      <div className="appointment__screen__book__time">
        {appointmentSlots?.message ? (
          <div style={{ color: 'red' }} className="mt-5 text-center">
            {appointmentSlots?.message}
          </div>
        ) : (
          <>
            <div className="appointment__screen__book__type__text">
              Select Time
            </div>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={11.3}
              minRange={0}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots.length === 0 ? true : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <WbCloudyOutlinedIcon />
                  <LightModeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Morning
                </div>
              </div>
            </TimeSelectedScreen>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={17}
              minRange={12}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots?.length === 0
                  ? true
                  : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <LightModeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Afternoon
                </div>
              </div>
            </TimeSelectedScreen>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={23.3}
              minRange={17.3}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots?.length === 0
                  ? true
                  : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <BedtimeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Evening
                </div>
              </div>
            </TimeSelectedScreen>
          </>
        )}
      </div>
      <div
        className={`appointment__screen__book__button ${
          !hiddeBookButton ? 'd-none' : ''
        }`}
      >
        <Button handleClick={handleBookAppointment}>Book</Button>
      </div>
    </div>
  );
};

export default AppointmentBook1;
