import React from 'react'

function MemoCash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={48}
      height={48}
      fill="none"
    >
      <path fill="url(#a)" d="M0 0h48v48H0z" />
      <defs>
        <pattern
          id="a"
          width={1}
          height={1}
          patternContentUnits="objectBoundingBox"
        >
          <use xlinkHref="#b" transform="scale(.02083)" />
        </pattern>
        <image
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABQElEQVRoge2XTU7DMBCFPVCVFUKILV33nPQAcA2oKrFmwY0gErnA10UdIblxcfwTx2g+KVKkaea9p9Se2BhFUZSayHADUNOIi4jI378y5qq0kdJogMXAOE8z6D6PCcc0OjMPrAt4dnXXwC57gInPboAD0NvrHdjOpZ/UwJr/HnmDHbBpIcBhxPzAvrS+d5CFDhKgN8bcesq9iNwF9onSL72NFp/uOQJ8Xqh9ZOgfRsIa2NoF6/IFPJbWz9KA0060B37s9TbFfKr+aANOE3KOQXYDvJQIALAr4NnVPTM/JUAz5wHX31Bv/mtUA9RmNdyEju6l0fwb0AC1CQ4QO2jsZ4WP1zjbvwQvXN8gCXiuM8bce8qdiDyk6M/xF1pdqF2nNp8jgJ4XUvSzNEDPC//4vBC9jdZGzwNLofkAiqIodTkC3/AJdIr3cT8AAAAASUVORK5CYII="
          id="b"
          width={48}
          height={48}
        />
      </defs>
    </svg>
  );
}

export default MemoCash