import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Dialog, Avatar } from '@mui/material';
import { OPD } from '../calls/api';
import { Ls } from '../utility/utility';
import Modal from '@mui/material/Modal';
import { PatientpageContext } from './../context/GlobalContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { baseUrl, constants, getLocal } from './../utility/constants';
// import { ServiceCall } from '../../utility/service-call';
// import { Ls } from '../../utility/utility';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import GTranslateOutlinedIcon from '@material-ui/icons/GTranslateOutlined';
import { ServiceCall } from '../utility/service-call';

import ProfileForm from '../components/ProfileForm';
import BioMarker from './BioMarker';
// import googletrans from '../static/images/googletranslate.jpg';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoImage: {
    '& img': {
      maxWidth: '80%',
      height: 'auto',
      objectFit: 'contain',
    },
  },
}));

export default function Header({
  pageTitle,
  page,
  apiResponse,
  onGoogleTranslateClick,
}) {
  // console.log(apiResponse?.phone,"header")
  const classes = useStyles();
  let history = useHistory();
  const { search } = useLocation();
  const patientContext = useContext(PatientpageContext);
  const [auth, setAuth] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rpath, setRpath] = React.useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  useEffect(() => {
    setAuth(Ls.get('userToken'));
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirectToPage = (val) => {
    // setRpath(val);
    history.push({
      pathname: '/home/1',
      search: search,
    });
  };
  const handleSignout = () => {
    patientContext.logOutCall();
  };
  const renderDialogContent = (contentType, content) => {
    if (contentType) {
      if (contentType === 'profileForm') {
        return <ProfileForm />;
      } else if (contentType === 'bioMarker') {
        return <BioMarker patientContext={patientContext} />;
      } else {
        return contentType;
      }
    } else {
      return content;
    }
  };
  const openProfileForm = () => {
    setAnchorEl(null);
    patientContext.openProfileForm();
  };
  const redirectComponent = () => {
    return <Redirect to={`${process.env.PUBLIC_URL}/${search}`} />;
  };
  const checkLoginPage = () => {
    if (patientContext.checkLoginPage && page === 'login') {
      return false;
    } else {
      return true;
    }
  };
  const openBioMarker = () => {
    setAnchorEl(null);
    patientContext.dialogController({
      open: true,
      contentType: 'bioMarker',
      maxWidth: 'sm',
      dialogAction: null,
      // disableBackdropClick: true,
    });
  };

  const logoutHandle = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
  
      const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');
      const currentTime = new Date().getTime() / 1000; // Convert to seconds
  
      if (tokenExpirationTime && currentTime > tokenExpirationTime) {
        // Token is expired, clear user data
        localStorage.removeItem('pt_key');
        localStorage.removeItem('pt_token');
        localStorage.removeItem('phone');
        setLoggedIn(false);
        history.push({
          pathname: `/`,
          search: history?.location?.search,
        });
      } else {
        // Token is not expired, proceed with the logout API call
        const deleteData = await ServiceCall.postv2(OPD?.patientlogout, '', '', {
          pt_token: Ls.get('pt_token'),
          pt_key: Ls.get('pt_key'),
        });
  
        // Clear user data
        localStorage.removeItem('pt_key');
        localStorage.removeItem('pt_token');
        localStorage.removeItem('phone');
        setLoggedIn(false);
  
        history.push({
          pathname: `/`,
          search: history?.location?.search,
        });
      }
    } catch (err) {
      // Handle any errors during the logout process
      console.error(err);
    }
  };
  

  const loginHandle = () => {
    history.push({
      pathname: `/login`,
      search: history?.location?.search,
    });
  };
  useEffect(() => {
    const userIsLoggedIn = !!(
      localStorage.getItem('pt_key') && localStorage.getItem('pt_token')
    );

    if (userIsLoggedIn) {
      // If logged in, retrieve and set user's phone number from wherever you store it
      const userPhoneNumber = localStorage.getItem('phone');
      setUserPhoneNumber(userPhoneNumber);
    }
    setLoggedIn(userIsLoggedIn);
  }, [])

  const handleback = () => {
    history.push({
      pathname: `/`,
      search: history?.location?.search,
    });
  };
  const handleGoogleTranslateClick = () => {
    // Call the function passed as a prop
    if (onGoogleTranslateClick) {
      onGoogleTranslateClick();
    }
  };

  return (
    <div className={classes.root}>
      {patientContext.hospitalError.hasError && redirectComponent()}
      <AppBar
        position="sticky"
        color="transparent"
        className="header-line "
        style={{ justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Dialog
          disableEnforceFocus
          open={patientContext.appDialog.open && checkLoginPage()}
          maxWidth={patientContext.appDialog.maxWidth}
          fullWidth
          sx={{ borderRadius: '30px' }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={patientContext.handleDialogClose}
          disableEscapeKeyDown={true}
        >
          <div
            className={`d-flex justify-content-flex-end pb-2 ${
              patientContext.appDialog.promptWindow ? 'pt-4' : ''
            }`}
          >
            {!patientContext.appDialog.promptWindow && (
              <IconButton
                onClick={patientContext.handleDialogClose}
                // color="success"
                sx={{ color: '#000' }}
                component="span"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>

          <div
            className={`${
              patientContext.appDialog.contentClass
                ? patientContext.appDialog.contentClass
                : 'pb-3 px-3 px-sm-5 text-break'
            }`}
          >
            {patientContext.appDialog.contentType
              ? renderDialogContent(
                  patientContext.appDialog.contentType,
                  patientContext.appDialog.content
                )
              : patientContext.appDialog.content}
          </div>
          {patientContext.appDialog.dialogAction && (
            <div className="pb-5 px-2 text-center">
              {patientContext.appDialog.dialogAction}
            </div>
          )}
        </Dialog>
        <Toolbar
          component="div"
          style={{ minHeight: '45px' }}
          className="d-flex justify-content-start mt-2 mb-2 align-items-center"
        >
          <div className="d-flex align-items-center " onClick={handleback}>
            <Avatar
              alt="logo"
              id="siteLogo"
              variant="square"
              className={`${classes.logoImage}`}
              sx={{ width: 40, height: 40, borderRadius: '25px' }}
              // src={`${baseUrl}${
              //   constants.testPath('mode') !== 'test'
              //     ? ''
              //     : '/' + constants.testPath('mode')
              // }/hospital/logo/${constants.testPath('hosp_id')}`}
              src={patientContext.hospitalDetails.brand_url}
            ></Avatar>
            <div style={{ marginLeft: '0.4rem' }}>
              <div style={{ fontWeight: '600' }} className="pl-2 font-14">
                {patientContext.hospitalDetails.facility_name}
              </div>
              <div className="d-flex align-items-center justify-content-start mt-1">
                <AddLocationIcon
                  sx={{
                    color: '#e62625',
                    fontSize: '16px',
                    marginRight: '0.4rem',
                  }}
                />{' '}
                <span className="font-10">
                  <a
                    style={{ color: '#333' }}
                    target="_blank"
                    href={
                      patientContext.hospitalDetails?.google_maps_url || '/'
                    }
                  >
                    {patientContext.hospitalDetails?.address}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="header-main">
            <button className="btn-1" onClick={handleGoogleTranslateClick}>
              <GTranslateOutlinedIcon />
            </button>
            {/* <button
              onClick={loginHandle}
              onMouseOver={() => {
                setAnchorEl(true);
              }}
            >
              <PersonOutlineIcon />

              {isLoggedIn ? (
                <>
                  <span>{localStorage.getItem('phone')}</span>
                  <div
                    className={`${
                      anchorEl ? 'd-flex' : 'd-none'
                    } headerContainer__dropDown`}
                    onMouseLeave={() => {
                      setAnchorEl(false);
                    }}
                  >
                    <ul>
                      <li onClick={logoutHandle}>Sign Out</li>
                    </ul>
                  </div>
                </>
              ) : (
                'LOGIN'
              )}
            </button> */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
