import React from 'react'

const MemoOtherIcon = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 34 34"
    >
      <circle cx="17" cy="17" r="17" fill={color || "#E8F3F1"}></circle>
      <path
        fill={!!color ? "#FFF" : "url(#paint0_linear_1404_160)"}
        fillRule="evenodd"
        d="M17.048 17.5c0 .026.002.05.005.075-.04 1.893-1.598 3.425-3.526 3.425C11.573 21 10 19.427 10 17.5s1.573-3.5 3.527-3.5c1.928 0 3.486 1.532 3.526 3.425a.493.493 0 00-.005.075zM13.527 22c2.33 0 4.25-1.75 4.5-4h1.094l-.01 1.104a.446.446 0 10.892.008L20.014 18h1.958l-.874.502a.442.442 0 00-.162.605.447.447 0 00.609.163l2.374-1.363a.442.442 0 00.208-.495.435.435 0 00-.213-.308l-2.399-1.377a.44.44 0 00-.6.16.435.435 0 00.16.598l.898.515h-1.949l.01-1.025a.446.446 0 10-.892-.009L19.132 17h-1.106c-.25-2.25-2.169-4-4.499-4C11.027 13 9 15.015 9 17.5s2.027 4.5 4.527 4.5z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1404_160"
          x1="9"
          x2="24.142"
          y1="17.5"
          y2="17.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC33A5"></stop>
          <stop offset="1" stopColor="#009DFE"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MemoOtherIcon