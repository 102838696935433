import React from 'react'

const MemoFemaleIcon = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 34 34"
    >
      <circle cx="17" cy="17" r="17" fill={color || '#E8F3F1'}></circle>
      <path
        stroke={!!color ? '#FFF' : '#FC33A5'}
        d="M20.554 14.5c0 2.206-1.8 4-4.027 4-2.227 0-4.027-1.794-4.027-4s1.8-4 4.027-4c2.227 0 4.027 1.794 4.027 4z"
      ></path>
      <rect
        width="1.006"
        height="7"
        x="16.024"
        y="18"
        fill={!!color ? '#FFF' : '#FC33A5'}
        rx="0.503"
      ></rect>
      <rect
        width="1"
        height="5.03"
        x="19.041"
        y="22"
        fill={!!color ? '#FFF' : '#FC33A5'}
        rx="0.5"
        transform="rotate(90 19.041 22)"
      ></rect>
    </svg>
  );
}

export default MemoFemaleIcon