import { constants, countriesList, baseUrl } from './constants';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import { razorpayTest, razorpay } from './constants';
class Ls {
  static set(key, value) {
    window.localStorage.setItem(key, value);
  }
  static remove(key) {
    return window.localStorage.removeItem(key);
  }
  static get(key) {
    return window.localStorage.getItem(key);
  }
  static flush() {
    window.localStorage.clear();
  }
  static length() {
    return window.localStorage.length();
  }
}
const getTimeSlots = (min = 30) => {
  let currentHour = 0;
  let helperHour = 0;
  let ampm = 'AM';
  let currentMinute = 0;
  let timeArr = [];
  for (let i = 0; i <= 48; i++) {
    let hourString =
      helperHour < 10 || helperHour === 0 ? '0' + helperHour : helperHour;
    let minString = currentMinute === 0 ? '0' + currentMinute : currentMinute;

    timeArr.push({
      data: hourString + ':' + minString + ' ' + ampm,
      value: `${currentHour}.${
        currentMinute === 0 ? currentMinute : currentMinute / 10
      }`,
    });
    currentMinute = currentMinute + min;
    if (currentMinute % 60 === 0) {
      currentHour = currentHour + 1;
      helperHour = currentHour > 12 ? currentHour - 12 : currentHour;
      ampm = currentHour >= 12 ? 'PM' : 'AM';
      currentMinute = 0;
    }
  }
  return timeArr;
};
const checkFutureToday = (date) => {
  let compareDate = '';
  if (date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {
    compareDate = 'future';
  }
  if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    compareDate = 'today';
  }
  if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
    compareDate = 'past';
  }
  return compareDate;
};
const convertTime = function (timeArr, timeDiff = 30) {
  let helperTimeArr = [];
  for (let i = 0; i < timeArr.length; i++) {
    let { from_time, to_time } = timeArr[i];
    let from = parseFloat(from_time);
    let to = parseFloat(to_time);
    let from_hour = parseInt(from_time.split('.')[0]);
    let from_minute = from_time.split('.')[1]
      ? parseInt(from_time.split('.')[1]) * 10
      : 0;
    let j = from;
    while (j <= to) {
      let timeslot = j.toFixed(2);
      let ampm = 'AM';
      let [tH, tM] = timeslot.split('.');
      ampm = parseInt(tH) < 12 ? 'AM' : 'PM';
      helperTimeArr.push({
        value: `${parseInt(tH) < 10 ? '0' + tH : tH}.${
          tM === 0 ? '0' + tM : tM
        }`,
        data: `${parseInt(tH) > 12 ? parseInt(tH) - 12 : parseInt(tH)}:${
          tM === 0 ? '0' + tM : tM
        } ${ampm}`,
        dayPart: `${ampm}`,
      });

      from_minute = from_minute + timeDiff;
      if (from_minute % 60 === 0) {
        from_minute = 0;
        from_hour = from_hour + 1;
      }

      let minute = from_minute > 0 ? from_minute / 100 : 0;
      j = from_hour + minute;
      // console.log('### ', j);
    }
  }
  return helperTimeArr;
};
const convertTimeSlot = function (timeArr) {
  let helperTimeArr = [];
  for (let i = 0; i < timeArr.length; i++) {
    let clonetimerArr = { ...timeArr[i] };
    let slotformated = dayjs(
      clonetimerArr.schedule_date + clonetimerArr.slot
    ).format('hh:mm A');
    let compareTime = clonetimerArr.slot.replace(':', '.');
    helperTimeArr.push({ ...clonetimerArr, slotformated, compareTime });
  }
  return helperTimeArr;
};

const razorpayCall = (
  appointmentId,
  callBack,
  orderDetails,
  profiledata,
  hospitalDetails
) => {
  // let logoPath = `${baseUrl}${constants.testPath("mode")!=='test'? '':'/'+constants.testPath("mode")}/hospital/logo/${constants.testPath("hosp_id")}`
  let logoPath = document.querySelector('#siteLogo img').getAttribute('src');

  var options = {
    key: Ls.get('mode') === 'test' ? razorpayTest : razorpay, // Enter the Key ID generated from the Dashboard
    amount:
      orderDetails?.amount_due * 100 * (1 - orderDetails?.amount_due / 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: orderDetails?.currency,
    name: hospitalDetails.hospital_name,
    description: 'Appointment Booking',
    image: logoPath,
    order_id: orderDetails?.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      let callbackObject = {
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: orderDetails?.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
        razorpay_error_code: response.payment_cancelled,
        razorpay_error_description: '',
        razorpay_error_source: '',
        razorpay_error_step: '',
        razorpay_error_reason: '',
        razorpay_error_metadata_order_id: '',
        razorpay_error_metadata_payment_id: '',
      };

      callBack(appointmentId, callbackObject);
    },
    prefill: {
      name: profiledata?.name,
      email: profiledata?.email || '',
      contact: profiledata?.phone,
    },
    modal: {
      ondismiss: function () {
        // alert('This was called when the popup was closed');
        callBack(
          appointmentId,
          {},
          {
            hasError: true,
            errorType: 'ondismiss',
            errorMessage: <div className="text-center">Payment Cancelled</div>,
          }
        );
      },
    },
    notes: {
      reciept: appointmentId, //appointment number
    },
    theme: {
      color: '#199A8E',
    },
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on('payment.failed', function (response) {
    document.body.style.overflow = 'visible';
    callBack(
      appointmentId,
      {},
      {
        hasError: true,
        errorType: 'failed',
        errorMessage: (
          <div className="text-center">
            <h3>
              It seems there has been some issue during the payment, please try
              booking the appointment again in some time.
            </h3>
            <h6>
              If amount is deducted from the account, it will be refunded back
              in 3-5 business days.
            </h6>
          </div>
        ),
      }
    );
  });
  rzp1.open();
};

const stringToColor = function (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const stringTrim = (name, length) => {
  let trim =
    name?.length > length ? name?.trim()?.substring(0, length) + '...' : name;
  return trim;
};
const convertTime24to12 = (time12h) => {
  // let timeToConvert = parseFloat(time12h)
  let [hours, minutes] = time12h.split(':');
  let modifier = 'AM';

  if (hours === '12') {
    hours = '12';
    modifier = 'PM';
  } else if (parseInt(hours) > 12) {
    hours = parseInt(hours) - 12;
    modifier = 'PM';
  } else {
    hours = parseInt(hours);
  }

  return `${hours}:${minutes} ${modifier}`;
};

let hex2rgba = (hex, a) => {
  return `rgba(${hex
    .substr(1)
    .match(/../g)
    .map((x) => +`0x${x}`)},${a})`;
};

function removeEmptyValues(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

const datesAreOnSameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};
export {
  countriesList,
  Ls,
  getTimeSlots,
  convertTime,
  razorpayCall,
  stringAvatar,
  convertTime24to12,
  datesAreOnSameDay,
  checkFutureToday,
  hex2rgba,
  stringTrim,
  convertTimeSlot,
  removeEmptyValues,
};
