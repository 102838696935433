import React, { useEffect, useContext } from 'react';
import { Avatar } from 'material-ui';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { PatientpageContext } from '../context/GlobalContext';
import { stringAvatar } from './../utility/utility';
import { apiCall } from '../utility/service-call';
import { Skeleton } from '@mui/material';
import { Ls } from './../utility/utility';

function ImageAvatar({
  imagePath,
  name,
  size,
  async = false,
  ID,
  circularpattern = 'rounded-circle',
}) {
  const [hasError, setErrors] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState("");
  const [imageLoader, setImageLoader] = React.useState('loading');

  const patientContext = useContext(PatientpageContext);

  useEffect(() => {
    if (!async) {
      setImageLoader('loaded');
    } else if (patientContext?.DynamicImageStore[ID]) {
      setImageLoader('loaded');
      setImgSrc(patientContext?.DynamicImageStore[ID]?.image);
    } else {
      setImageError();
    }

    return () => {
      setImageLoader('loaded');
    };
  }, []);

  const setImageError = async function (e) {
    await apiCall({
      url: imagePath,
      method: 'get',
      responseType: 'blob',
      docID: `/${ID}` || '',
      headers: {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      },
    })
      .then((res) => {
        setImageLoader('loaded');
        setImgSrc(URL.createObjectURL(res.data));
        if (ID) {
          patientContext?.setDynamicImageStore({
            ...patientContext?.DynamicImageStore,
            [ID]: {
              image: URL.createObjectURL(res.data),
            },
          });
        }
      })
      .catch((err) => {
        setErrors(true);
        console.log('image-error', err);
      });
  };

  return (
    <>
      {hasError ? (
        <div
          // src={imagePath}
          className={`${circularpattern} d-flex align-items-center justify-content-center`}
          style={{
            width: size,
            color: 'white',
            height: size,
            // backgroundColor: stringAvatar(name)['sx']['bgcolor'],
          }}
        >
          <AccountCircleRoundedIcon
            sx={{ width: '100%', color: '#199a8e', fontSize: '4rem' }}
          />
        </div>
      ) : (
        <>
          <div
            className={`${circularpattern}`}
            style={{ overflow: 'hidden', width: size, height: size }}
          >
            {imageLoader === 'loaded' ? (
              <img
                src={imgSrc}
                alt={name}
                className="img-fluid"
                style={{
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  objectFit: 'cover',
                  color: 'transparent',
                  textIndent: '10000px',
                }}
                onError={(err) => {
                  setErrors(true);
                  console.log("error on image", err)
                }}
              ></img>
            ) : (
              <Skeleton variant="circular" width={40} height={40} />
            )}
          </div>
          {/* <Avatar
            size='40px'
            className='with image'
            alt={name}
            src={imagePath}
          ></Avatar> */}
        </>
      )}
    </>
  );
}

export default React.memo(ImageAvatar);
