import React, { useState, useContext, useEffect } from 'react';
import { AppointmentType } from '../utility/config';
import { useLocation, useHistory } from 'react-router-dom';
import { PatientpageContext } from '../context/GlobalContext';
import dayjs from 'dayjs';
import { profileIcon } from '../static/ImageManager';
import { ServiceCall } from '../utility/service-call';
import TimeSelectedScreen from './TimeSelectedScreen';
import WbCloudyOutlinedIcon from '@mui/icons-material/WbCloudyOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import styles from '../styles/appointmentScreensStyle';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Tools/Button';
import { OPD } from '../calls/api';
import { Ls } from '../utility/utility';

const AppointmentBook = ({ setCurrSlide }) => {
  const patientContext = useContext(PatientpageContext);
  const useStyles = makeStyles(styles, { index: 1 });
  const classes = useStyles();
  const [appointmentSlots, setAppointmentSlots] = useState([]);

  const history = useHistory('');

  const isLoggedIn = () => {
    return Ls.get('pt_key') && Ls.get('pt_token');
  };

  useEffect(() => {
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      pickedSlot: '',
    });
    getAvailableSlots();
  }, [
    patientContext?.slectedAppointment?.appointment_type,
    patientContext?.slectedAppointment?.appointment_date,
  ]);

  const hiddeBookButton =
    !!patientContext?.slectedAppointment?.pickedSlot?.slot &&
    !!patientContext?.slectedAppointment?.appointment_type &&
    !!patientContext?.slectedAppointment?.appointment_date;

  const getAvailableSlots = async () => {
    setAppointmentSlots([]);
    let url = `/${Ls.get('hosp_id')}/${
      patientContext?.slectedAppointment?.doc?.doc_id
    }/${patientContext?.slectedAppointment?.appointment_type}/${dayjs(
      patientContext?.slectedAppointment?.appointment_date
    ).format('YYYY-MM-DD')}`;
    try {
      let availableSlots = await ServiceCall.getv2(OPD?.availableDoctor, url, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (availableSlots) {
        setAppointmentSlots(availableSlots?.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    const Patientpi = () => {
      if (appointmentSlots && appointmentSlots.length > 0) {
        sessionStorage.setItem(
          'selectedType',
          patientContext?.slectedAppointment?.appointment_type
        );
    
        sessionStorage.setItem(
          'selectedDate',
          patientContext?.slectedAppointment?.appointment_date
        );
        sessionStorage.setItem(
          'selectedTime',
          patientContext?.slectedAppointment?.pickedSlot?.slot
        );
  

        history.push({
          pathname: '/patientpro',
          search: `&redirectfrom=consultation&btn=book-appointment${history?.location?.search}`,
          state:{
            appointmentSlots:appointmentSlots
          }
        });
      }
    };
    Patientpi();
  }, [  patientContext?.slectedAppointment?.appointment_type,
    patientContext?.slectedAppointment?.appointment_date,
  ]);

  

  const handleBookAppointment = () => {
    if (!isLoggedIn()) {

      history.push({
        pathname: `/login`,
        search:
          history?.location?.search +
          `&redirectfrom=consultation&btn=book-appointment`,
        state: { redirectToPatientpro: true },
      });

      let data = {
        appointment_date: dayjs(
          patientContext?.slectedAppointment?.appointment_date
        )?.format('YYYY-MM-DD'),
        time_alloted: patientContext?.slectedAppointment?.pickedSlot?.slot
          ?.split(':')
          ?.join('.'),
        is_inperson:
          patientContext?.slectedAppointment?.appointment_type !==
          AppointmentType?.online
            ? true
            : false,
        slot_duration:
          patientContext?.slectedAppointment?.pickedSlot?.slot_duration,
        end_time: patientContext?.slectedAppointment?.pickedSlot?.end_time
          ?.split(':')
          ?.join('.'),
        is_walkin_appointment: false,
      };
  
      sessionStorage.setItem('scheduleAppData', JSON.stringify(data))

      return;
    }

    let url = `/${Ls.get('hosp_id')}/${
      patientContext?.slectedAppointment?.doc?.doc_id
    }/${patientContext?.slectedAppointment?.user?.profile_id}`;
    let data = {
      appointment_date: dayjs(
        patientContext?.slectedAppointment?.appointment_date
      )?.format('YYYY-MM-DD'),
      time_alloted: patientContext?.slectedAppointment?.pickedSlot?.slot
        ?.split(':')
        ?.join('.'),
      is_inperson:
        patientContext?.slectedAppointment?.appointment_type !==
        AppointmentType?.online
          ? true
          : false,
      slot_duration:
        patientContext?.slectedAppointment?.pickedSlot?.slot_duration,
      end_time: patientContext?.slectedAppointment?.pickedSlot?.end_time
        ?.split(':')
        ?.join('.'),
      is_walkin_appointment: false,
    };
    patientContext
      ?.initiateAppointment(OPD?.bookSlot, url, data)
      .then((bookedSlot) => {
        if (
          patientContext?.slectedAppointment?.appointment_type ===
            AppointmentType?.inperson &&
          bookedSlot
        ) {
          setCurrSlide(2);
        } else if (bookedSlot) {
          patientContext?.setSelectedAppointment({
            ...patientContext?.slectedAppointment,
            bookedSlot: bookedSlot,
            payment_type: 'online',
          });

          setCurrSlide(3);
        }
      });
  };

  const handleChange = (value) => {
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      pickedSlot: value,
    });
  };
  return (
    <div className="appointment__screen__book">
      <div className="appointment__screen__book__type">
        <div className="appointment__screen__book__type__text">Select Type</div>
        <div className="appointment__screen__book__type__box">
          <div
            className={`appointment__patient__button ${
              patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.online
                ? 'appointment__patient__button__selected'
                : ''
            }`}
            onClick={() => {
              patientContext?.setSelectedAppointment({
                ...patientContext?.slectedAppointment,
                appointment_type: AppointmentType?.online,
              });
            }}
          >
            Online (Video)
          </div>
          <div
            className={`appointment__patient__button ${
              patientContext?.slectedAppointment?.appointment_type ===
              AppointmentType?.inperson
                ? 'appointment__patient__button__selected'
                : ''
            }`}
            onClick={() => {
              patientContext?.setSelectedAppointment({
                ...patientContext?.slectedAppointment,
                appointment_type: AppointmentType?.inperson,
              });
            }}
          >
            In-Person (at Facility)
          </div>
        </div>
      </div>
      <div className="appointment__screen__book__date">
        <div className="appointment__screen__book__type__text">Select Date</div>
        <div className="appointment__screen__book__date__input">
          <label for="appointment-date">
            {patientContext?.slectedAppointment?.appointment_date}
          </label>
          <input
            id="appointment-date"
            type="date"
            name="appointment_date"
            value={patientContext?.slectedAppointment?.appointment_date}
            min={dayjs().format('YYYY-MM-DD')}
            onChange={(e) => {
              if (e.target.value) {
                patientContext?.setSelectedAppointment({
                  ...patientContext?.slectedAppointment,
                  appointment_date: dayjs(e.target.value)?.format(
                    'ddd MMM DD YYYY'
                  ),
                });
              }
            }}
          />
          <img src={profileIcon?.calendar} alt="profile-name-icon" />
        </div>
      </div>
      <div className="appointment__screen__book__time">
        {appointmentSlots?.message ? (
          <div style={{ color: 'red' }} className="mt-5 text-center">
            {appointmentSlots?.message}
          </div>
        ) : (
          <>
            <div className="appointment__screen__book__type__text">
              Select Time
            </div>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={11.3}
              minRange={0}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots.length === 0 ? true : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <WbCloudyOutlinedIcon />
                  <LightModeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Morning
                </div>
              </div>
            </TimeSelectedScreen>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={17}
              minRange={12}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots?.length === 0
                  ? true
                  : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <LightModeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Afternoon
                </div>
              </div>
            </TimeSelectedScreen>
            <TimeSelectedScreen
              classes={classes}
              appointments={appointmentSlots?.time_slots}
              handleChange={handleChange}
              maxRange={23.3}
              minRange={17.3}
              selectedDate={dayjs(
                patientContext?.slectedAppointment?.appointment_date
              ).format('YYYY-MM-DD')}
              selectedTime={
                patientContext?.slectedAppointment?.pickedSlot?.slot
              }
              bookAppLoader={
                appointmentSlots && appointmentSlots?.length === 0
                  ? true
                  : false
              }
            >
              <div className="appointment__screen__book__time__wrapper">
                <div className="appointment__screen__book__time__icon">
                  <BedtimeOutlinedIcon />
                </div>
                <div className="appointment__screen__book__time__title">
                  Evening
                </div>
              </div>
            </TimeSelectedScreen>
          </>
        )}
      </div>
      <div
        className={`appointment__screen__book__button ${
          !hiddeBookButton ? 'd-none' : ''
        }`}
      >
        <Button handleClick={handleBookAppointment}>Book</Button>
      </div>
    </div>
  );
};

export default AppointmentBook;
