import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: '#ffffff',
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    left: 0,
    right: 0,
  },
}));


export default function Overlay(props) {
   
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
      window.document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [])
  
  const classes = useStyles();
  const { opacity = '0.85' } = props;
  return (
    <div
      style={{ opacity: opacity }}
      className={`${classes.overlay} overlay pos-absolute w-100 h-100 d-flex justify-content-center align-items-center`}
    >
      {props.children}
    </div>
  );
}
