import { Container, Typography, Button } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Box } from '@mui/material';
import { PatientpageContext } from './../../context/GlobalContext';
import Overlay from '../../components/Overlay';
import { Ls } from '../../utility/utility';
export default function JoinPage(props) {
  let { roomid } = useParams();
  const patientContext = useContext(PatientpageContext);
  const [report, setReport] = useState({});
  const { notesLoader } = patientContext;
  let history = useHistory();
  let timerRef = useRef(null);

  useEffect(() => {
    if (!roomid) {
      clearTimeout(timerRef.current);
      if (Ls.get('userToken')) {
        history.push('/details');
      } else {
        history.push('/');
      }
    } else {
      if (
        !patientContext.profileData ||
        !patientContext.profileData.patient_profile ||
        !patientContext.profileData.patient_profile.name
      ) {
        patientContext
          .getProfile()
          .then((res) => {
            console.log(res.data);
            // if(res.data && res.data.patient_profile.name){

            // }
          })
          .catch((err) => {
            history.push('/');
          });
      }
    }
  }, [roomid, patientContext.profileData]);

  useEffect(() => {
    if (!notesLoader) {
      timerRef.current = setTimeout(() => {
        patientContext
          .getNotes('c564794c-1e09-4fd0-9039-426f1cb7')
          .then((res) => {
            // console.log(JSON.parse(res.doc_findings));
            setReport(res);

            console.log(res);
          });
      }, 10000);
    }
    return function () {
      clearTimeout(timerRef.current);
    };
  }, [notesLoader, patientContext, roomid]);

  return (
    <>
      <Header pageTitle={props.pageTitle} />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {patientContext.profileData &&
              patientContext.profileData.patient_profile &&
              patientContext.profileData.patient_profile.name && (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title={roomid}
                    scrolling="no"
                    className="embed-responsive-item"
                    allow="camera; microphone; fullscreen; speaker; display-capture"
                    src={`https://rxcall.yourvideo.app/${roomid}?landing=no&name=${patientContext.profileData.patient_profile.name}`}
                    allowfullscreen
                  ></iframe>
                </div>
              )}
          </Grid>
          <Grid content item xs={12} sm={6} className="pos-relative p-3">
            {patientContext.notesLoader && (
              <Overlay>
                <div className="text-center">
                  <CircularProgress size={16} />
                  <p>Loading Report..</p>
                </div>
              </Overlay>
            )}
            <Grid item>
              <Box></Box>
              {report.patient_symptoms &&
                Object.keys(report['patient_symptoms']).map((item) => (
                  <div> {report['patient_symptoms'][item]}</div>
                ))}
              {/* {Object.keys(report['doc_findings']).map((item) => (
                <div> {item}</div>
              ))}
              {Object.keys(report['doc_recommendations']).map((item) => (
                <div> {item}</div>
              ))} */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
