export const OPD = {
  getOtp: '/v2/pwa/generate/patient/otp/',
  patientLogin: '/v2/pwa/auth/patient/',
  profileList: '/v2/list/patient/profiles/',
  encryptPayload: '/v2/patient/payload/enc/',
  profileSignup: '/v2/patient/self/profile/',
  updateProfile:'/v2/patient/profile/detail/',
  familySignup: '/v2/patient/add/family/profile/',
  listDoctors: '/v2/pwa/patient/home/',
  headerpro: '/v2/pwa/patient/global/header/',
  patientlogout: '/v2/logout/patient/',
  availableDoctor: '/v2/patient/list/doc/slots/',
  bookSlot: '/v2/patient/book/appointment/',
  bookfollowup: '/v2/patient/book/followup/',
  initiateWalkin: '/v2/patient/book/walkin/appointment/',
  raiseOrder: '/v2/patient/book/appointment/order/',
  raiseOrderWalkin: '/v2/patient/walkin/appointment/order/',
  fetchOline: '/v2/patient/fetch/appointment/order/',
  verifyPayment: '/v2/patient/payment/confirm/',
  fetchNextAvailableSlog: '/v2/patient/fetch/appointment/booking/options/',
  fetchfollowup: '/v2/patient/fetch/appointment/followup/options/',
  
  fetchProfileByID:'/v2/patient/profile/detail/',
  langData: '/v2/pwa/patient/list/locals',
};