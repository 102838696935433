import React from 'react';
import { Grid } from '@mui/material';
// import logo from './img/rx-logo.png';

export default function Footer() {
  return (
    <div className=" mt-auto footer py-3 ">
      <Grid item xs={6} className="font-10 px-1 d-flex footer-link">
        <a href="https://rxone.app/privacypolicy" target="_blank">
          Privacy Policy
        </a>
        <a href="https://rxone.app/terms" target="_blank">
          Terms of Usage
        </a>
      </Grid>
      <div className="d-flex">
        <div style={{ fontSize: '12px' }} className="mx-2">
          Powered By
        </div>
        <a href="https://rxone.app/" rel="noreferrer" target="_blank">
          <img
            alt="powered by"
            className="footerLogo"
            src={`${process.env.PUBLIC_URL}/img/rxone-logo.png`}
          />
        </a>
      </div>
    </div>
  );
}
