import React, { useContext } from 'react';
import ThankupageImage from '../static/images/thankuImage.jpg';
import AppStoreIcon from '../static/images/app-store.png';
import PlayStoreIcon from '../static/images/play-store.png';
import playstoregif from '../static/playstoregif.webp';
import appstoregif from '../static/appstore.gif';
import Header from './Header';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Ls } from '../utility/utility';
import RxoneLogo from '../static/images/rxone-logo.png';
import Button from '../Tools/Button';
import { PatientpageContext } from '../context/GlobalContext';

const ThankuPage = () => {
  const patientContext = useContext(PatientpageContext);
  const history = useHistory();


  const handleButtonClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN",
      "_blank"
    );
  };

  const handleAppleClick = () => {
    window.open(
      "https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685",
      "_blank"
    );
  };
  return (
    <>
      <Header />
      <div className="thankuPage__container">
        <div
          className="thankuPage__close"
          onClick={() => {
            // Ls.remove('pt_key');
            // Ls.remove('pt_token');
            // patientContext?.setSelectedAppointment({
            //   ...patientContext?.slectedAppointment,
            //   doc: '',
            // });
            history.push({
              pathname: '/',
              search: history?.location?.search,
            });
          }}
        >
          <CloseIcon />
        </div>
        <div className="thankuPage__heading">All Set!</div>
        <div className="thankuPage__image">
          <img src={ThankupageImage} alt="thanku-page" />
        </div>
        <div className="thankuPage__text">
          {patientContext?.slectedAppointment?.payment_type !== 'online'
            ? patientContext?.slectedAppointment?.bookedSlot?.message
            : `Your appointment is booked successfully with
          ${patientContext?.slectedAppointment?.doc?.doctor_name}. Notification
          has been sent on your registered mail-id and Phone.`}
        </div>
        {/* <div className="thankuPage__button">
          <Button containerClass="">
            <div className="thankuPage__button__style">
              <img src={RxoneLogo} alt="rxone-logo" />
              <span>COMING SOON !</span>
            </div>
          </Button>
        </div> */}
        <div className="thankuPage__social_icon">
          <img src={appstoregif} alt="app-store" onClick={handleAppleClick} />
          <img src={playstoregif} alt="play-store" onClick={handleButtonClick} />
        </div>
      </div>
    </>
  );
};

export default ThankuPage;
