import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import { PatientpageContext } from '../context/GlobalContext';
import { OPD } from '../calls/api';
import { profileIcon } from '../static/ImageManager';
import CustomButton from '../Tools/Button';
import { bloodGroup } from '../utility/config';
import MemoMaleIcon from '../static/svg-component/MemoMaleIcon';
import CloseIcon from '@mui/icons-material/Close';
import { removeEmptyValues } from '../utility/utility';
import MemoFemaleIcon from '../static/svg-component/MemoFemaleIcon';
import MemoOtherIcon from '../static/svg-component/MemoOtherIcon';
import { debounce } from 'lodash';
import Footer from './Footer';
export default function ProfileForm({
  heading,
  showProfileForm = null,
  setProfileForm = null,
}) {
  const isFamily = showProfileForm && showProfileForm?.type === 'new';
  const patientContext = useContext(PatientpageContext);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [agreementForm, setAgreementForm] = useState(false);
  const [profileVal, setProfileVal] = useState({
    email: '',
    name: '',
    gender: '',
    blood_group: '',
    birth_date: '',
    age: '',
    agree: false,
  });

  const [encProfileVal, setEncProfileVal] = useState({
    email: '',
    name: '',
    gender: '',
    blood_group: '',
    birth_date: '',
  });

  const fillUserForm = async () => {
    const profileID = patientContext?.slectedAppointment?.user?.profile_id;
    patientContext
      ?.getProfileById(OPD?.fetchProfileByID, `/${profileID}`)
      .then((list) => {
        setProfileVal({
          ...profileVal,
          email: list?.email,
          name: list?.name,
          gender: list?.gender?.toLowerCase(),
          blood_group: list?.blood_group,
          // birth_date: list?.birth_date,
          birth_date: dayjs(list?.birth_date).format('YYYY-MM-DD'),
          age: list?.age || '',
        });
      });
  };

  // console.log('profileVal', profileVal);

  useEffect(async () => {
    if (showProfileForm && !isFamily) {
      fillUserForm();
    } else {
      const EmailValue =
        Array.isArray(patientContext?.profileData?.profiles) &&
        !!patientContext?.profileData?.profiles?.length &&
        patientContext?.profileData?.profiles[0]?.email;
      if (EmailValue) {
        setProfileVal({ ...profileVal, email: EmailValue, gender: 'male' });
        const email = await patientContext?.Encrypt(EmailValue);
        const gender = await patientContext?.Encrypt('male');
        if (email && gender) {
          setEncProfileVal({
            ...encProfileVal,
            email: email,
            gender: gender,
          });
        }
      } else {
        handleChangeProfileDebounce('gender', 'male');
        setProfileVal({ gender: 'male' });
      }
    }

    return () => {
      handleChangeProfileDebounce.cancel();
    };
  }, []);

  const handleChangeProfileDebounce = useCallback(
    debounce(async function (flag, value) {
      const enValue = await patientContext?.Encrypt(value);
      if (enValue && flag !== 'age') {
        setEncProfileVal({ ...encProfileVal, [flag]: enValue });

        // if (!value) {
        //   setEncProfileVal({ ...encProfileVal, [flag]: '' });
        // } else

        // setEncProfileVal({ ...encProfileVal, [flag]: enValue });
      }
    }, 1000),
    [encProfileVal]
  );

  const changeProfile = async (e) => {
    let name = e.target.name;
    let value = e.target.value.toString();
    let radio = e.target.checked;

    // console.log('value', value);

    if (name === 'agree') {
      setProfileVal({ ...profileVal, [name]: radio });
    } else {
      setProfileVal({ ...profileVal, [name]: value.toString() });
      handleChangeProfileDebounce(name, value);
    }

    if (profileVal?.age?.length) {
      const currentDate = dayjs();
      const birthDate = currentDate
        .subtract(profileVal.age, 'years')
        .format('YYYY-MM-DD');
      const enValue = await patientContext?.Encrypt(birthDate);
      if (enValue) {
        setEncProfileVal({ ...encProfileVal, birth_date: enValue });
      }
    }
  };

  // console.log(profileVal.birth_date);
  const RenderDateField = ({ handleChange }) => {
    let minRange = dayjs().format('YYYY-MM-DD');
    let maxRange = dayjs().add(7, 'day').format('YYYY-MM-DD');

    return (
      <>
        {/* <TextField
          id="date"
          required
          label="Birth Date"
          name="birth_date"
          className="w-100 mt-2 mb-2 birth_date"
          type="date"
          InputProps={{
            inputProps: {
              onKeyPress: (e) => {
                e.preventDefault();gi
              },
            },
          }}
          value={profileVal.birth_date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        <div className="profile_form__input">
          <img src={profileIcon?.calendar} alt="profile-name-icon" />
          {!profileVal?.birth_date ? (
            <label for="date">Date Of Birth</label>
          ) : (
            <></>
          )}
          <input
            id="date"
            type="date"
            name="birth_date"
            // placeholder="Date of birth"
            required
            value={profileVal.birth_date}
            disabled={profileVal.age && profileVal.age !== ''}
            // disabled={profileVal.age !== ''}
            onChange={handleChange}
          />
        </div>
      </>
    );
  };

  // console.log(profileVal);

  const submitProfile = async (e) => {
    e.preventDefault();

    // console.log(profileVal);

    // if (profileVal?.age?.length) {
    //   const currentDate = dayjs();
    //   const birthDate = currentDate
    //     .subtract(profileVal.age, 'years')
    //     .format('YYYY-MM-DD');
    //   const enValue = await patientContext?.Encrypt(birthDate);
    //   if (enValue) {
    //     setEncProfileVal({ ...encProfileVal, birth_date: enValue });
    //   }
    // }

    // console.log('object4444444', encProfileVal, profileVal);

    if (showProfileForm && showProfileForm?.form) {
      if (isFamily) {
        // console.log('object1111111');
        patientContext
          .submitProfile(OPD?.familySignup, encProfileVal)
          .then(
            (item) =>
              item && setProfileForm({ ...showProfileForm, form: false })
          );
      } else {
        // console.log('object2222222');
        patientContext
          .updateProfile(
            OPD?.updateProfile,
            `/${patientContext?.slectedAppointment?.user?.profile_id}`,
            removeEmptyValues(encProfileVal)
          )
          .then((item) => {
            if (item) {
              setProfileForm({ ...showProfileForm, form: false });
            }
          });
      }
    } else {
      if (!agreementForm) {
        setAgreementForm(true);
      } else {
        // console.log('object33333333', encProfileVal, profileVal);
        patientContext.submitProfile(OPD?.profileSignup, encProfileVal);
      }
    }
  };
  return (
    <form onSubmit={submitProfile} className="px-4 px-sm-3 pt-4 pos-relative">
      {agreementForm ? (
        <>
          <Typography
            className="text-start mt-4"
            variant="h6"
            sx={{ fontWeight: 600, fontSize: '16px' }}
            gutterBottom
            component="div"
          >
            USER AGREEMENT
          </Typography>
          <p className="profile_form__agreement">
            Welcome to RxOne Care application.
          </p>
          <p className="profile_form__agreement">
            Please carefully read the Terms of Use of the Application(here) and
            Privacy Policy (here)
          </p>
          <p className="profile_form__agreement">
            By clicking on “Accept & Sign Up’ button at the end of this page or
            accessing or using the application, you hereby accept the Terms of
            Use (as amended from time to time) and the Privacy Policy (as
            amended from time to time) and agree to bound by their terms.
          </p>
          <p className="profile_form__agreement">
            If you do not agree to be bound by the their terms, please do not
            access or use the application.
          </p>
          <p className="profile_form__agreement mb-4">
            Further, by clicking on the ‘Accept & Sign Up’ button, you hereby
            consent to the collection, storage, use, processing, disclosure and
            transfer of your personal information in accordance
          </p>
          <CustomButton containerClass="profile_form">
            {patientContext?.submitProfileLoader ? (
              <CircularProgress size={16} sx={{ color: '#FFF' }} />
            ) : (
              <>Accept & Sign up</>
            )}
          </CustomButton>
        </>
      ) : (
        <>
          <Typography
            className="text-center profile_form__heading"
            variant="h6"
            gutterBottom
            component="div"
          >
            {heading || 'CREATE YOUR ACCOUNT'}
          </Typography>
          {showProfileForm?.form ? (
            <div
              onClick={() => {
                setProfileForm({ ...showProfileForm, form: false });
              }}
              className="profile_form__close"
            >
              <CloseIcon />
            </div>
          ) : (
            <></>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="profile_form__input">
                <img src={profileIcon?.name} alt="profile-name-icon" />
                <input
                  id="name"
                  name="name"
                  required
                  placeholder="Enter Name"
                  value={profileVal.name}
                  onChange={changeProfile}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="profile_form__input">
                <img src={profileIcon?.mail} alt="profile-name-icon" />
                <input
                  id="email"
                  name="email"
                  required
                  placeholder="Enter Email"
                  value={profileVal.email}
                  title="Please enter a valid email address."
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
                  onChange={changeProfile}
                />
              </div>
              {/* <TextField
            type="email"
            required
            id="email"
            fullWidth
            name="email"
            label="Email"
            variant="standard"
            value={profileVal.email}
            onChange={changeProfile}
            inputProps={{
              autoComplete: 'off',
              pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
            }}
          /> */}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} item>
              <div className="profile_form__gender">
                <label className="profile_form__gender__label" for="gender">
                  Gender
                </label>
                <div className="profile_form__gender__input">
                  <label for="male">
                    <MemoMaleIcon
                      color={profileVal?.gender === 'male' ? '#199A8E' : ''}
                    />
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      onChange={changeProfile}
                    />
                    <div>Male</div>
                  </label>
                  <label for="female">
                    <MemoFemaleIcon
                      color={profileVal?.gender === 'female' ? '#199A8E' : ''}
                    />
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      onChange={changeProfile}
                    />
                    <div>Female</div>
                  </label>
                  <label for="other">
                    <MemoOtherIcon
                      color={profileVal?.gender === 'other' ? '#199A8E' : ''}
                    />
                    <input
                      type="radio"
                      id="other"
                      name="gender"
                      value="other"
                      onChange={changeProfile}
                    />
                    <div>Other</div>
                  </label>
                </div>
              </div>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={5}>
                {/* <RenderDateField handleChange={changeProfile} /> */}
                <div className="profile_form__input">
                  <img src={profileIcon.age} alt="profile-age-icon" />
                  <input
                    id="age"
                    name="age"
                    type="number"
                    required
                    placeholder="Enter Age"
                    value={profileVal.age}
                    onChange={changeProfile}
                    disabled={
                      profileVal.birth_date && profileVal.birth_date !== ''
                    }
                    // disabled={profileVal.birth_date.length > 0}
                    // disabled={profileVal.birth_date !== ''}
                  />
                </div>
              </Grid>
              <Grid item xs={2} className="other_sec">
                <p>Or</p>
              </Grid>
              <Grid item xs={5}>
                {/* <div className="profile_form__input">
                  <img src={profileIcon.age} alt="profile-age-icon" />
                  <input
                    id="age"
                    name="age"
                    type="number"
                    required
                    placeholder="Enter Age"
                    value={profileVal.age}
                    onChange={changeProfile}
                    onClick={handleAgeFieldClick}
                    disabled={selectedField === 'dob'}
                  />
                </div> */}
                <RenderDateField handleChange={changeProfile} />
              </Grid>
            </Grid>

            <Grid container spacing={1}></Grid>
          </Grid>
          {showProfileForm?.form ? (
            <>
              <Grid container spacing={1}>
                <div className="profile_form__blood">
                  <label className="profile_form__gender__label" for="gender">
                    Blood Group
                  </label>
                  <div>
                    {bloodGroup?.map((item) => (
                      <>
                        <label
                          className={`profile_form__blood_circle ${
                            profileVal?.blood_group === item
                              ? 'profile_form__blood_circle_selected'
                              : ''
                          } `}
                          for={item}
                        >
                          {item}
                          <input
                            type="radio"
                            name="blood_group"
                            value={item}
                            onChange={changeProfile}
                          />
                        </label>
                      </>
                    ))}
                  </div>
                </div>
              </Grid>
              {showProfileForm?.type !== 'update' && (
                <Grid container spacing={1}>
                  <div className="profile_form__readmore">
                    <div
                      className={`profile_form__readmore__box ${
                        profileVal?.agree ? 'profile_form__readmore__tick' : ''
                      }`}
                    >
                      <input
                        name="agree"
                        value={profileVal?.agree}
                        required
                        type="radio"
                        onChange={changeProfile}
                      />
                    </div>
                    <label
                      className="profile_form__readmore__label"
                      for="agree"
                    >
                      I hereby declare that I am lawfully authorized to provide
                      the above information on behalf of owner of the
                      information
                    </label>
                  </div>
                </Grid>
              )}
            </>
          ) : (
            <></>
          )}
          {showProfileForm?.type !== 'update' && (
            <Grid item xs={12} className="d-flex pt-3">
              <div className="font-14 footer-link m-auto">
                <span>By creating this you accept our</span>
                <a href="https://rxone.app/terms" target="_blank">
                  Terms of Usage
                </a>
              </div>
            </Grid>
          )}
          <Grid container className="text-center pt-5 justify-content-center">
            <CustomButton containerClass="profile_form">
              {patientContext?.profileLoader ||
              patientContext?.submitProfileLoader ? (
                <CircularProgress size={16} sx={{ color: '#FFF' }} />
              ) : showProfileForm?.form ? (
                <>Save</>
              ) : (
                <>Create Account</>
              )}
            </CustomButton>
          </Grid>
        </>
      )}
                {showProfileForm?.type !== 'update' && (

       <Footer /> 
                )}
    </form>
  );
}
