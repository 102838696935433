import React, { useContext } from 'react';
import ImageAvatar from './ImageAvatar';
import { stringTrim } from '../utility/utility';
import { PatientpageContext } from '../context/GlobalContext';
import MemoDiscount from "../static/svg-component/MemoDiscount"
const AppointmentCart = ({ list, handleClick }) => {
  const patientContext = useContext(PatientpageContext);
  return (
    <>
      <div
        className={`appointment__container ${
          patientContext?.slectedAppointment?.doc?.doc_id === list?.doc_id
            ? 'appointment__container--active'
            : ''
        }`}
        onClick={handleClick}
      >
        <div className="appointment__top">
          <div className="appointment__avatar">
            <ImageAvatar
              async
              imagePath={`/v2/patient/doctor/pic/`}
              ID={list?.doc_id}
            />
          </div>
          <div className="">
            <div className="appointment__doc">
              <div className="appointment__doc__name">
                {stringTrim(list?.doctor_name || '', 15)}
              </div>
              <div className="appointment__doc__spc">
                {list?.speciality || ''}
              </div>
              <div className="appointment__doc__exp">
                {list?.qualification || ''} {list?.experience || ''} years of
                experience
              </div>
            </div>
            <div className="appointment__amount">
              <div className="appointment__charges">
                <p>
                  {list?.currency || ''} {list?.consult_charge}
                </p>
              </div>
            </div>
          </div>
        </div>
        {!!list?.online_discount ? (
          <div className="appointment__bottom">
            <MemoDiscount />{' '}
            <p>{list?.online_discount_stmt || `${list?.online_discount}% Discount on online payment.`}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AppointmentCart;
