import React, { useState, useEffect, useContext } from 'react';
import { ServiceCall } from '../../utility/service-call';
import { constants } from '../../utility/constants';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Footer from '../../components/Footer';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import Header from '../../components/Header';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PatientpageContext } from './../../context/GlobalContext';
import { Ls } from '../../utility/utility';
import { useLocation } from 'react-router-dom';
import Logo from '../../static/images/rxone-logo.png';
import ProfileForm from '../../components/ProfileForm';
import PhoneIcon from '@mui/icons-material/Phone';
import { OPD } from '../../calls/api';
const Ordertest = () => {
  const patientContext = useContext(PatientpageContext);

  const [orderdata, setOrderdata] = useState([]);
  const history = useHistory();
  const { search } = useLocation();

  const fetchData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');
    try {
      const Ordertest = await ServiceCall.getv2(
        OPD?.listDoctors,
        `/pathology/${constants.hospitalId('hosp_id')}?lang=${langParam ? langParam : 'en'}`
      );
      if (Ordertest.status === 200) {
        setOrderdata(Ordertest.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  

  const handlebuttonclick = () => {
    history.push({
      pathname: `/`,
      search: history?.location?.search,
    });
  };

  useEffect(() => {
    if (Ls.get('pt_key') !== null && Ls.get('pt_token') !== null) {
      if (!patientContext.hospitalLoader) {
        patientContext.getProfile();
        // patientContext.getAppointment();
        // patientContext.getRecords();
        // patientContext.getReceipts();
      }
    }
    
    // else {
    //   history.push({
    //     pathname: '/',
    //     search: search,
    //   });
    // }
  }, [patientContext.hospitalLoader]);

  return (
    <>
      <Header page="login"></Header>
      {patientContext?.profileForm?.enable ? (
        <div className="details__page">
          <div className="details__page_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="details__page_profile">
            <ProfileForm />
          </div>
        </div>
      ) : (
        <div className="pathology-selection">
          <h2>
            <button onClick={handlebuttonclick} className="back-button">
              <ChevronLeftIcon />
            </button>
          </h2>
          <Grid container spacing={2} direction="column">
            {orderdata.message && (
              <div className="msg">{orderdata.message}</div>
            )}
            <div className="connect-new-card">
              {orderdata.records &&
                orderdata.records.map((pathology) => (
                  <div key={pathology.lab_id} className="pathology-button">
                    <Button
                      variant="outlined"
                    
                    >
                      <div className="heading-col-pathology">
                        <div className="pathology-img">
                          <figure>
                            <img
                              src={pathology.brand_logo_url}
                              alt={pathology.lab_name}
                            />
                          </figure>
                        </div>

                        <div className="pathology-title">
                          <h2>{pathology.lab_name}</h2>

                          <a
                            style={{ color: '#333' }}
                            target="_blank"
                            href={pathology.geo_location}
                          >
                            <div className="time">
                              <span>
                                <TimerIcon /> {pathology.start_time}{' '}
                              </span>
                              <span>
                                <TimerOffIcon /> {pathology.end_time}
                              </span>
                            </div>
                          </a>
                        </div>

                        <div className="pathology-address">
                          <div className="phn">
                          <PhoneIcon sx={{
                            fontSize: '16px',
                            marginRight: '1.4rem',
                          }}/>
                            {pathology.phone1}</div>
                          <AddLocationIcon
                            sx={{
                              color: '#e62625',
                              fontSize: '16px',
                              marginRight: '0.4rem',
                            }}
                          />
                          {pathology.address}
                        </div>
                      </div>
                    </Button>
                  </div>
                ))}
            </div>
          </Grid>
        </div>
      )}
      <Footer />
    </>
  );
};
export default Ordertest;
