import React,{useContext, useEffect,useState} from 'react'
import FreshChat from "react-freshchat";
import { PatientpageContext } from '../context/GlobalContext';

function FreshChatApp(button) {
  const [name, setName] = useState("Rxone.Doctor")
  const [ email,setEmail] = useState("")
  const patientContext = useContext(PatientpageContext);
  const patient =patientContext.profileData;

  console.log("patient",patient)

  useEffect(()=>{
    if(patient){
      setName(patient.name)
      setEmail(patient.phone)
    }
  },[patient,button])
 
  const userData ={
    name: name,
    email:email ,
    }

    // console.log("username",user.name)
  return (

    <div className=''>
        <FreshChat
       token={"d69fd409-d0b4-4880-a7cf-77cdb6e8e4ff"}
        host={"https://wchat.in.freshchat.com"}
        config={{
          headerProperty: {
            backgroundColor: "#16434d",
            foregroundColor: "#ffffff"
          }
        }}
        onInit={widget => {
        //   Use `widget` instead of `window.fcWidget`
            widget.user.setProperties({
              email: userData.email,
              firstName: userData.name,
              // lastName: "mandawariya",
              // phone: "9999999999",
               
              
            })
            widget.on("user:created", function (resp) {
              console.log(11, resp);
              // var status = resp && resp.status,
              //   data = resp && resp.data;
            })
        
        }}
      />
    </div>
  )
}

export default FreshChatApp