import React, { useState, useContext, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import TodayIcon from '@mui/icons-material/Today';
import DescriptionIcon from '@mui/icons-material/Description';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MyRecordsTab from './MyRecordsTab';
import AppointmentReceipts from './AppointmentReceipts';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import FaqMoald from "../FAQ/Faq"
import Overlay from './Overlay';
import CircularProgress from '@mui/material/CircularProgress';
import AppointmentTab from './AppointmentTab';
import { PatientpageContext } from './../context/GlobalContext';
import JoinTab from './JoinTab';
import MonitorComponent from './MonitorComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const tabIndexConst = {
  appointment: 0,
  records: 1,
  receipts: 2,
  monitor: 3,
  join: 4,
};
export default function DetailsTabs({
  loader,
  classes,
  history,
  doctorsList,
  toggleAppointmentLists,
}) {
  let { tabIndex } = useParams();
  const patientContext = useContext(PatientpageContext);
  const [value, setValue] = useState(tabIndex ? parseInt(tabIndex) : 0);
  const [showAppointment, setShowAppointment] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const submitPatientDetails = function () {
    history.push('/payment');
  };
  const toggleAppointment = function () {
    if (showAppointment) {
      setShowAppointment(false);
    } else {
      setShowAppointment(true);
    }
  };
  useEffect(() => {
    if (patientContext.currentTab) {
      setValue(tabIndexConst[patientContext.currentTab]);
    }
  }, [patientContext.currentTab]);
  return (
    <>
      {patientContext.overlayLoading === 'loading' && (
        <Overlay>
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AppointmentTab
            doctorsList={doctorsList}
            toggleAppointment={toggleAppointment}
            showAppointment={showAppointment}
            appointmentList={patientContext.appointmentList}
            classes={classes}
            appointMentLoader={loader}
          />
        </Box>
      </Box>
    </>
  );
}
