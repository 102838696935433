import React from 'react';
import ContinueIcon from "../static/images/Arrow--right.svg"
import { CircularProgress } from '@material-ui/core';
const LoginButton = ({
  onSubmit,
  loader = false,
  color,
  loginClass="",
  disabled = false,
  props,
}) => {
  return (
    <button
      className={`Arrow--right ${loginClass}`}
      style={{ backgroundColor: `${disabled ? '#c0c0c0' : ''}` }}
      onClick={onSubmit}
      disabled={disabled}
      {...props}
    >
      {loader ? (
        <CircularProgress size={16} color={color || 'primary'} />
      ) : (
        <img src={ContinueIcon} />
      )}
    </button>
  );
};

export default LoginButton;
