import React, { useContext } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { PatientpageContext } from '../context/GlobalContext';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = ({ msg }) => {
  const doctorContext = useContext(PatientpageContext);
  const state = doctorContext.state;
  const setState = doctorContext.setState;
  const handleClose = () => {
    setState({ ...state, msg: '', type: '' });
  };
  if (state?.msg) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: state?.vertical,
          horizontal: state?.horizontal,
        }}
        autoHideDuration={state?.timer}
        open={state.msg ? true : false}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state?.type || 'info'}
          sx={{ width: '100%' }}
        >
          {state?.msg}
        </Alert>
      </Snackbar>
    );
  }
  return <></>;
};

export default SnackbarAlert;
