import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { ServiceCall } from '../../utility/service-call';
import { constants } from '../../utility/constants';
import { useHistory, Link, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import LogIn from './Login';
import loginIcon from '../../static/images/loginIcon.svg';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { PatientpageContext } from '../../context/GlobalContext';
import styles from './../../styles/appointmentScreensStyle';
import { makeStyles } from '@material-ui/core/styles';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Header from '../../components/Header';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PharmacySelection from './PharmacySelection';
import { Ls } from '../../utility/utility';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { OPD } from '../../calls/api';
import ButtonStyle from '../../Tools/Button';
import AppointmentTab from '../../components/AppointmentTab';
const Home = (toggleAppointment) => {
  const [homedata, setHomedata] = useState([]);
  const [isExpanded, setExpanded] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [overlayLoading, setOverlayLoading] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(false);
  const patientContext = useContext(PatientpageContext);
  // const[data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState({});
  const [onGoogleTransClick, setOnGoogleTransClick] = useState(false);
  const [showAppointmentTab, setShowAppointmentTab] = useState(false);

  const history = useHistory();

  const handleGoogleTranslateClick = () => {
    setOnGoogleTransClick(true);

    setOverlayLoading('loading');
    ServiceCall.getnewv2(OPD?.langData)
      .then((res) => {
        if (res.data) {
          const langData = res.data;

          patientContext.dialogController({
            open: true,
            content: (() => (
              <div className="text-front">
                <p>Select Language</p>
                {Object.entries(langData).map(([key, value]) => (
                  <label key={key}>
                    <input
                      type="radio"
                      name="language"
                      value={value}
                      onChange={(e) =>
                        setLang({
                          lang: e.target.value,
                        })
                      }
                      onClick={handleLanguageSelection}
                    />
                    {key}
                  </label>
                ))}
              </div>
            ))(),
            dialogAction: null,
            maxWidth: 'xs',
            // maxHeight: '100vh',
          });
        }
      })
      .catch((err) => {});
  };
  // const handleGoogleTranslateClick = () => {
  //   // Call the Selectlang function
  //   Selectlang();
  // };

  const fetchDataForSelectedLanguage = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');
    setLoading(true);
    try {
      const homeFetch = await ServiceCall.getv2(
        OPD?.listDoctors,
        `/${constants.hospitalId('hosp_id')}?lang=${
          langParam ? langParam : 'en'
        }`,
        {
          headers: {
            'Accept-Language': langParam || 'en',
          },
        }
      );
      if (homeFetch.status === 200) {
        setHomedata(homeFetch.data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching home data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageSelection = (e) => {
    e.preventDefault();
    const selectedLang = e.target.value;

    if (selectedLang && selectedLang !== lang.lang) {
      // Close the language selection modal
      patientContext.dialogController({
        open: false,
      });

      // Set the new language in the state
      setLang({
        lang: selectedLang,
      });
      // Fetch data for the selected language and update the state
      // console.log(5565656, lang)
      fetchDataForSelectedLanguage();

      // Update the URL with the selected language
      const currentUrl = window.location.href;
      const langQueryParam = `lang=${selectedLang}`;

      let updatedUrl;

      // Check if the URL already contains a question mark
      if (currentUrl.includes('lang=')) {
        // If it does, replace the existing lang parameter with the new one
        updatedUrl = currentUrl.replace(/lang=[^&]+/, langQueryParam);
      } else {
        // If there's no lang parameter, append it with a question mark or ampersand
        updatedUrl = currentUrl.includes('?')
          ? `${currentUrl}&${langQueryParam}`
          : `${currentUrl}?${langQueryParam}`;
      }
      // Replace the current URL with the updated URL
      window.location.replace(updatedUrl);
    }
  };

  useEffect(() => {
    setLoading(true);
    const userIsLoggedIn = !!(
      localStorage.getItem('pt_key') && localStorage.getItem('pt_token')
    );

    if (userIsLoggedIn) {
      // If logged in, retrieve and set user's phone number from wherever you store it
      const userPhoneNumber = localStorage.getItem('phone');
      setUserPhoneNumber(userPhoneNumber);
    }
    setLoggedIn(userIsLoggedIn);
    setOnGoogleTransClick(false);

    // if(!localStorage.getItem('pt_token')){
    //   history.push({
    //     pathname: `/login`,
    //     search: history?.location?.search,
    //   });
    // }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let langParam = queryParams.get('lang');

    if (!langParam) {
      // If langParam is not present in the URL, set it to 'en' (English)
      langParam = 'en';
    }

    if (onGoogleTransClick) {
      // "lang" param is not present or onGoogleTransClick is true,
      // show the language selection modal

      // Call the Google Translate click function
      handleGoogleTranslateClick();
    } else {
      // "lang" param is present, fetch data for the selected language
      fetchDataForSelectedLanguage(langParam);
    }
  }, [lang, onGoogleTransClick]);

  const handleMenu = (event) => {
    setAnchorEl(true);
  };


  useEffect(() => {
    if (!Ls.get('pt_key') && !Ls.get('pt_token')) {
      if (!patientContext.hospitalLoader) {
        // patientContext.getProfile();
        patientContext.getAppointment();
        // patientContext.getRecords();
        // patientContext.getReceipts();
      }
    }
    
    // else {
    //   history.push({
    //     pathname: '/',
    //     search: search,
    //   });
    // }
  }, [patientContext.hospitalLoader]);

  const handleButtonClick = (service) => {
    const { facility_type_en } = service;
    // console.log(454545, facility_type);

    // if (!isLoggedIn) {
    //   const queryString = new URLSearchParams(history.location.search);

    //   if (facility_type_en === 'CONSULTATION') {

    //     queryString.set('redirectfrom', 'consultation');
    //   }

    // else if(facility_type === 'PHARMACY'){
    //   queryString.set('redirectfrom', 'pharmacy');

    // }
    // else if(facility_type === 'PATHOLOGY'){
    //   queryString.set('redirectfrom', 'pathology');

    // }
    // else {
    //   queryString.set('redirectfrom', facility_type.toLowerCase());
    // }

    //   history.push({
    //     pathname: '/login',
    //     search: queryString.toString(),
    //   });
    //   return;
    // }

    if (facility_type_en === 'CONSULTATION') {
      history.push({
        pathname: `/consultation`,
        search: history?.location?.search,
      });
      
      window.location.reload();
      // setShowAppointmentTab(true);
    } else if (facility_type_en === 'PHARMACY') {
      history.push({
        pathname: `/pharmacy`,
        search: history?.location?.search,
      });
    } else if (facility_type_en === 'PATHOLOGY') {
      history.push({
        pathname: `/pathology`,
        search: history?.location?.search,
      });
    }
  };

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    const userIsLoggedIn = !!(
      localStorage.getItem('pt_key') && localStorage.getItem('pt_token')
    );

    if (!userIsLoggedIn) {
      // If the user is not logged in, navigate to the home page
      history.push({
        pathname: `/`,
        search: history?.location?.search,
      });
    }
    setLoggedIn(userIsLoggedIn);
  }, [history]);

  const numberOfButtons = homedata?.offered_services?.length || 0;


  return (
    <>
      <Header
        page="login"
        onGoogleTranslateClick={handleGoogleTranslateClick}
      ></Header>
      {showAppointmentTab ? (
        <AppointmentTab toggleAppointment={toggleAppointment} />
      ) : loading ? ( // Check if loading is true
        <Backdrop
          sx={{
            color: '#fff',
            opacity: '0.6',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      ) : (
        <div className={`home ${numberOfButtons > 1 ? 'min-height-100vh' : ''}`}>

          <Grid xs={12} sm={14} className="home_container">
            <div className="home_sub_container">
              {homedata && (
                <div className="address_num">
                  <div className="emergency-service-bar">
                    <div class="ticker-texts">
                      {homedata?.ticker_texts?.join(' | ')}
                    </div>
                  </div>
                  <div className="welcome-card">
                    <div className="welcome-crd">
                      <h4>{homedata.welcome_title}</h4>
                      {isExpanded ? (
                        <p>{homedata.welcome_message}</p>
                      ) : (
                        <div className="message-container">
                          <p>
                            {homedata.welcome_message &&
                              homedata.welcome_message.slice(0, 150)}

                            <span onClick={toggleExpand}>...Read More</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {homedata &&
                      homedata.images_urls &&
                      Array.isArray(homedata.images_urls) && (
                        <OwlCarousel
                          className="owl-theme"
                          autoplay={true}
                          autoplayTimeout="1200"
                          smartSpeed="800"
                          loop={true}
                          margin={10}
                          dots={false}
                        >
                          {homedata.images_urls.map((imageUrl, index) => (
                            <div key={index} className="item">
                              <div className="customImg">
                                <img src={imageUrl} alt={`Slide ${index}`} />
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}

                    <div className="title">{homedata.services_title}</div>
                  </div>
                </div>
              )}
            </div>
            <div className="outerItem">
              <Grid container spacing={2} direction="column">
                <div className="connect-with-card">
                  {homedata?.offered_services ? (
                    homedata.offered_services.map((service, serviceIndex) => (
                      <Grid item key={serviceIndex}>
                        <Button
                          variant="outlined"
                          onClick={() => handleButtonClick(service)}
                        >
                          <img src={service.icon_url} alt={service.title} />
                          {service.title}
                        </Button>
                        {/* <Typography variant="body2">{service.message}</Typography> */}
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2">No services found.</Typography>
                  )}
                </div>
              </Grid>
            </div>
          </Grid>

          <div className="social-links">
            {homedata.facility_social_links?.facebook_link && (
              <a
                style={{ color: '#333' }}
                target="_blank"
                href={homedata.facility_social_links.facebook_link}
              >
                <FacebookIcon
                  sx={{
                    color: '#19619f',
                    fontSize: '24px',
                    marginRight: '0.4rem',
                  }}
                />
              </a>
            )}

            {homedata.facility_social_links?.twitter_link && (
              <a
                style={{ color: '#333' }}
                target="_blank"
                href={homedata.facility_social_links.twitter_link}
              >
                <TwitterIcon
                  sx={{
                    color: '#19619f',
                    fontSize: '24px',
                    marginRight: '0.4rem',
                  }}
                />
              </a>
            )}

            {homedata.facility_social_links?.youtube_link && (
              <a
                style={{ color: '#333' }}
                target="_blank"
                href={homedata.facility_social_links.youtube_link}
              >
                <YouTubeIcon
                  sx={{
                    color: '#e62625',
                    fontSize: '24px',
                    marginRight: '0.4rem',
                  }}
                />
              </a>
            )}

            {homedata.facility_social_links?.linkedin_link && (
              <a
                style={{ color: '#333' }}
                target="_blank"
                href={homedata.facility_social_links.linkedin_link}
              >
                <LinkedInIcon
                  sx={{
                    color: '#19619f',
                    fontSize: '24px',
                    marginRight: '0.4rem',
                  }}
                />
              </a>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
