import React from 'react';
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { datesAreOnSameDay, convertTime24to12 } from './../utility/utility';

export default function TimeSelectedScreen(props) {
  const {
    classes = '',
    appointments = [],
    handleChange,
    maxRange = 23.3,
    minRange = 0,
    selectedTime,
    selectedDate,
    bookAppLoader,
  } = props;

  function SumHours(slot) {
    var propsDate = new Date(selectedDate);

    var currentDate = new Date();
    if (!datesAreOnSameDay(propsDate, currentDate)) {
      return false;
    }

    var currentHr = currentDate.getHours();
    var currentMin = currentDate.getMinutes();
    var smon = currentHr + ':' + currentMin;
    var fmon = slot.split('.').join(':');
    var diff = 0;
    if (smon && fmon) {
      smon = ConvertToSeconds(smon);
      fmon = ConvertToSeconds(fmon);
      diff = fmon - smon;
      let getDiff = secondsTohhmmss(diff);
      if (getDiff.hours > 0) {
        return false;
      } else if (getDiff.hours < 0) {
        return true;
      } else {
        if (getDiff.minutes < 50) {
          return true;
        }
      }
    }

    function ConvertToSeconds(time) {
      var splitTime = time.split(':');
      return splitTime[0] * 3600 + splitTime[1] * 60;
    }

    function secondsTohhmmss(secs) {
      var hours = parseInt(secs / 3600);
      var seconds = parseInt(secs % 3600);
      var minutes = parseInt(seconds / 60);
      return { hours, minutes };
    }
  }

  return (
    <>
      {props.children}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {appointments.map(
          (item, index) =>
            parseFloat(item.slot) >= parseFloat(minRange) &&
            parseFloat(item.slot) <= parseFloat(maxRange) && (
              <Box key={`${item}-${index}`} className="font-15">
                <input
                  type="radio"
                  id={item.slot}
                  name="appointment_slot"
                  className={classes.radioTime}
                  disabled={
                    bookAppLoader || SumHours(item.slot) || !item.is_available
                  }
                  value={item}
                  checked={selectedTime && selectedTime === item?.slot}
                  onChange={(e) => handleChange(item)}
                />

                <label htmlFor={item.slot}>
                  <Box
                    className={`${classes.timeButton} mx-sm-2 mx-1 font-15`}
                    sx={{
                      px: 1,
                      py: 1,
                      mb: 1,
                      borderRadius: 1,
                    }}
                  >
                    {convertTime24to12(item.slot)}
                  </Box>
                </label>
              </Box>
            )
        )}
      </Box>
      {bookAppLoader ? (
        <Skeleton variant="rounded" width={310} height={120} />
      ) : (
        <></>
      )}
    </>
  );
}
