import React, { createContext, useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import styles from './../styles/appointmentScreensStyle';

import { useHistory } from 'react-router-dom';
import { isEqual, reject, result } from 'lodash';
import dayjs from 'dayjs';
import { AppointmentType } from '../utility/config';
import { Ls, datesAreOnSameDay } from './../utility/utility';
import { getLocal } from './../utility/constants';
import { ServiceCall, apiCall } from './../utility/service-call';
import { OPD } from '../calls/api';
import ButtonStyle from '../Tools/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FreshChat from 'react-freshchat';
import Button from '@mui/material/Button';
import { SelectAllRounded } from '@material-ui/icons';
import PhoneIcon from '@mui/icons-material/Phone';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ContactsIcon from '@mui/icons-material/Contacts';
const useStyles = makeStyles(styles);
export const PatientpageContext = createContext();

export const useQuery = () => {
  const { search, key } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const defaultDialogSettings = {
  open: false,
  content: '',
  contentType: null,
  maxWidth: 'sm',
  disableBackdropClick: true,
  promptWindow: false,
  dialogAction: null,
  contentClass: 'pb-3 px-3 px-sm-5 text-break',
};

const keymap = {
  body_temp_fr: {
    name: 'Body Temperature',
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  dias_blood_pressure: {
    containerLabel: 'Blood Pressure',
    name: 'Diastolic Blood Pressure',
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  heart_rate: {
    name: 'Heart Rate',
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  spo2_level: {
    name: 'SPO2 Level',
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  },
  syst_blood_pressure: {
    containerLabel: 'Blood Pressure',
    name: 'Systolic Blood Pressure',
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  },
};

const isLoggedIn = () => {
  // console.log("heyyy")
  // console.log("pt_key",Ls.get('pt_key'))
  // console.log("pt_token",Ls.get('pt_token'))
  return Ls.get('pt_key') && Ls.get('pt_token');
};

export default function GlobalContext(props) {
  let history = useHistory();
  const location = useLocation();
  let query = useQuery();
  let globalTimer = useRef(null);
  const defaultAppointmentData = {
    doc: '',
    user: '',
    type: '',
    appointment_type: AppointmentType?.online,
    appointment_date: dayjs().format('ddd MMM DD YYYY'),
    nextslot: '',
    pickedSlot: '',
    bookedSlot: '',
    raiseOrder: '',
    payment_type: '',
    fetchOrder: '',
  };
  const [appointmentLoader, setAppointmentLoader] = useState('loading');
  const [overlayLoading, setOverlayLoading] = useState('');
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentLastUpdate, setAppointmentLastUpdate] = useState('');
  const [toggleAppointmentLists, setToggleAppointmentLists] = useState(false);
  const [appDialog, setappDialog] = useState(defaultDialogSettings);
  const [recordData, setRecordData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [submitProfileLoader, setSubmitProfileLoader] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [profileForm, setProfileForm] = useState({
    class: '',
    enable: false,
    contentType: '',
  });
  const [recordLoader, setRecordLoader] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false);
  const [DynamicImageStore, setDynamicImageStore] = useState({});
  const [dialogSettings, setDialogSettings] = useState(defaultDialogSettings);
  const [hospitalDetails, setHospitalDetails] = useState({});
  const [receiptLoader, setReceiptLoader] = useState('');
  const [receipts, setReceipts] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState('');
  const [callActive, setCallActive] = useState(false);
  const [hospitalLoader, setHospitalLoader] = useState(true);
  const [monitorData, setMonitorData] = useState(null);
  const [monitorDataLoader, setMonitorDataLoader] = useState('');
  const [joinActive, setJoinActive] = useState([]);
  const [lang, setLang] = useState({});
  const [currentTab, setCurrentTab] = useState('');
  const [slectedAppointment, setSelectedAppointment] = useState(
    defaultAppointmentData
  );
  const [bookflw, setBookflw] = useState(defaultAppointmentData);

  // console.log("slectedAppointment",slectedAppointment)

  const appointmentRef = useRef(null);
  const [hospitalError, setHospitalError] = useState({
    hasError: false,
    errorType: 'missing',
  });
  const [state, setState] = useState({
    msg: '',
    type: '',
    timer: 3000,
    vertical: 'top',
    horizontal: 'center',
  });
  const [preTemplateLoader, setPreTemplateLoader] = useState(false);
  // let { mode, hospitalId } = useParams();
  

  const classes = useStyles();
  const handle401 = () => {
    history.push('/');
  };

  const setActiveAppointment = function (appointments) {
    let addedIsInActive = appointments.map((item) => {
      // if (!item['isInActive']) {
      //   item['isInActive'] = true;
      // }
      // item['isInActive'] = checkDisable(item);
      return item;
    });

    return addedIsInActive;
  };
  const checkDisable = (item) => {
    // setJoinActive([]);
    // console.log('join button');
    // var propsDate = new Date(item.appointment_date.replace(''));
    // var dateParts = item.appointment_date.split('-');

    // month is 0-based, that's why we need dataParts[1] - 1

    // var propsDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var propsDate = new Date(item.appointment_date);
    // console.log(propsDate);
    var currentDate = new Date();
    // console.log(!datesAreOnSameDay(propsDate, currentDate))
    if (!datesAreOnSameDay(propsDate, currentDate)) {
      return true;
    }
    let allotedMin =
      parseInt(item.time_alloted.split('.')[0]) * 60 +
      parseInt(item.time_alloted.split('.')[1]);
    let allotedMax =
      parseInt(item.end_time.split(':')[0]) * 60 +
      parseInt(item.end_time.split(':')[1]);
    let allotedTimeslot = allotedMax - allotedMin;
    let date = new Date();
    let totalMin = date.getHours() * 60 + date.getMinutes();
    let minDiff = allotedMin - totalMin;

    // console.log(item.appointment_date, minDiff);
    if (minDiff <= 0 && minDiff >= parseFloat(allotedTimeslot) * -1) {
      setJoinActive([item]);
      return false;
    } else {
      setJoinActive([]);
      return true;
    }
  };
  const activeJoinAppointment = function (appointmentList) {
    let currentAppointment = appointmentList.filter(
      (item) => item.isInActive === false
    );
    if (currentAppointment.length > 0) {
      setJoinActive([currentAppointment[0]]);
    } else {
      setJoinActive([]);
    }
  };

  // const timerAppointment = function () {
  //   clearInterval(globalTimer);
  //   globalTimer = setInterval(function () {
  //     if (appointmentList.length > 0) {
  //       // console.log('setActive')
  //       const appointmentClone = setActiveAppointment(
  //         JSON.parse(JSON.stringify(appointmentList))
  //       );
  //       if (!isEqual(appointmentClone, appointmentList)) {
  //         setAppointmentList(appointmentClone);
  //         activeJoinAppointment(appointmentClone);
  //       }
  //     }
  //   }, 1000);
  // };

  const getAppointment = function () {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');
    let hosp_id = `/consultation/${Ls.get('hosp_id')}?lang=${
      langParam ? langParam : 'en'
    }`;
    if (globalTimer !== null) {
      clearInterval(globalTimer.current);
    }
    setOverlayLoading('loading');
    ServiceCall.getv2(OPD?.listDoctors, hosp_id, {
      // pt_token: Ls.get('pt_token'),
      // pt_key: Ls.get('pt_key'),
    })
      .then((res) => {
        setAppointmentLoader('completed');
        setOverlayLoading('completed');
        // console.log(res.data);
        // console.log(res.data.appointments);
        if (res.data) {
          // let activeAppointment = setActiveAppointment(res.data.appointments);
          setAppointmentList({ ...res.data, doctors: res.data.records });
          setAppointmentLastUpdate(dayjs().format('YYYY-MM-DD HH:mm:ss'));
          // appointmentRef.current = activeAppointment;
          // activeJoinAppointment(activeAppointment);
        } else {
          setAppointmentList([]);
        }
      })
      .catch((err) => {
        setAppointmentLoader('error');
        setOverlayLoading('error');
      });
  };

  useEffect(() => {
    // getHospitalDetails();
    initialRouteCheck();
    // getRecords();
    // getReceipts();
    globalTimer.current = setInterval(function () {
      if (appointmentList.length > 0) {
        // console.log('setActive')
        const appointmentClone = setActiveAppointment(
          JSON.parse(JSON.stringify(appointmentList))
        );
        if (!isEqual(appointmentClone, appointmentList)) {
          setAppointmentList(appointmentClone);
          activeJoinAppointment(appointmentClone);
        }
      }
    }, 1000);
    return function () {
      clearInterval(globalTimer.current);
    };
  }, []);

  useEffect(() => {
    if (profileForm.enable) {
      setProfileForm({
        class: '',
        enable: false,
        contentType: '',
      });
      handleDialogClose();
    }
  }, [location.key]);

  // useEffect(() => {
  //   if (appointmentRef.current !== null && appointmentRef.current.length > 0) {
  //     clearInterval(globalTimer);
  //     // timerAppointment();
  //   }
  // }, [appointmentRef.current, globalTimer]);

  const dialogController = function (optionObject) {
    let dialogObject = {
      ...appDialog,
      dialogAction: (() => (
        <ButtonStyle
          handleClick={handleDialogClose}
          className="mr-2"
          containerClass="m-auto "
        >
          Ok
        </ButtonStyle>
      ))(),
      ...optionObject,
    };
    setDialogSettings(dialogObject);
    setappDialog(dialogObject);
  };
  const handleDialogClose = (event, reason) => {
    if (
      reason === 'backdropClick' &&
      (dialogSettings.disableBackdropClick || dialogSettings.promptWindow)
    ) {
      return false;
    }
    setappDialog(defaultDialogSettings);
  };
  const logOutCall = () => {
    dialogController({
      open: true,
      content: (() => (
        <div className="text-center">
          <h3>Logging Out</h3>
          <CircularProgress />
        </div>
      ))(),
      dialogAction: null,
      maxWidth: 'sm',
      disableBackdropClick: true,
    });
    ServiceCall.post('/patient/logout', '', {
      pt_token: Ls.get('userToken'),
    })
      .then((res) => {
        handleDialogClose();
        Ls.flush();
        window.location.reload();
      })
      .catch((err) => {
        handleDialogClose();
        Ls.flush();
        window.location.reload();
      });
  };

  const Encrypt = async (payload) => {
    return new Promise(async (resolve, reject) => {
      setProfileLoader(true);
      try {
        const EncryptMsg = await ServiceCall.postv2(
          OPD.encryptPayload,
          '',
          { payload: payload },
          {
            pt_token: Ls.get('pt_token'),
            pt_key: Ls.get('pt_key'),
          }
        );

        if (EncryptMsg && EncryptMsg?.data?.enc_payload) {
          setProfileLoader(false);
          resolve(EncryptMsg?.data?.enc_payload);
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 401) {
            handle401();
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        setProfileLoader(false);
        reject(error);
      }
    });
  };

  const getProfileById = async (url, id) => {
    setOverlayLoading('loading');
    try {
      let profileResponse = await ServiceCall.getv2(url, id, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });

      if (profileResponse) {
        setOverlayLoading('complete');
        return profileResponse?.data;
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        if (error.response.status === 401) {
          handle401();
        }
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      handleDialogClose();
      dialogController({
        open: true,
        content: 'Try again in sometime',
        contentType: null,
      });
      console.log(error.config);
      setOverlayLoading('complete');
    }
  };

  const getProfile = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let profileResponse = await ServiceCall.getv2(OPD.profileList, '', {
          pt_token: Ls.get('pt_token'),
          pt_key: Ls.get('pt_key'),
        });

        if (!profileResponse.data.profiles.length) {
          // openProfileForm();
          dialogController({
            open: true,
            content: (() => (
              <div className="text-center">
                <h3>
                  Seems you are login in for the first time. Please proceed to
                  create a profile
                </h3>
                {/* <h6>
                  We would need your basic details while booking the
                  appointment.
                </h6> */}
                <button onClick={() => history.goBack()} className="stylebtn">
                  X
                </button>
              </div>
            ))(),
            promptWindow: true,
            checkLoginPage: true,
            dialogAction: (() => (
              <>
                <ButtonStyle
                  handleClick={openProfileForm}
                  className="mr-2"
                  containerClass="m-auto "
                >
                  Proceed
                </ButtonStyle>
                {/* <Button
                  className={`${classes.blackBtn}`}
                  variant="contained"
                  onClick={handleDialogClose}
                >
                  Maybe Later
                </Button> */}
              </>
            ))(),
            maxWidth: 'sm',
            disableBackdropClick: true,
          });
        } else {
          setProfileData(profileResponse.data);
          setSelectedAppointment({
            ...slectedAppointment,
            user: profileResponse.data?.profiles[0],
          });
        }
        resolve(profileResponse);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 401) {
            handle401();
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        reject(error);
      }
    });
  };

  const updateProfile = async (url, profileId, profileData) => {
    setSubmitProfileLoader(true);
    try {
      const updateProfileForm = await ServiceCall.putv2(
        url,
        profileId,
        profileData,
        {
          pt_token: Ls.get('pt_token'),
          pt_key: Ls.get('pt_key'),
        }
      );
      if (updateProfileForm) {
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: <p className="text-center">Profile updated successfully.</p>,
          contentType: null,
          dialogAction: (() => (
            <>
              <ButtonStyle
                handleClick={() => {
                  setProfileForm({
                    class: '',
                    enable: false,
                    contentType: '',
                  });
                  handleDialogClose();
                }}
                className="mr-2"
                containerClass="m-auto "
              >
                ok
              </ButtonStyle>
            </>
          ))(),
        });
        getProfile();
        return updateProfileForm?.data;
      }
    } catch (err) {
      console.log(err);
      setSubmitProfileLoader(false);
      handleDialogClose();
      dialogController({
        open: true,
        content: 'Try again in sometime',
        contentType: null,
      });
    }
  };

  const submitProfile = async (url, profileData) => {
    setSubmitProfileLoader(true);
    try {
      const ProfileForm = await ServiceCall.postv2(url, '', profileData, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (ProfileForm) {
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: <p className="text-center">Profile saved successfully.</p>,
          contentType: null,
          dialogAction: (() => (
            <>
              <ButtonStyle
                handleClick={() => {
                  setProfileForm({ class: '', enable: false, contentType: '' });
                  handleDialogClose();
                }}
                className="mr-2"
                containerClass="m-auto "
              >
                ok
              </ButtonStyle>
            </>
          ))(),
        });
        getProfile();
        return ProfileForm?.data;
      }
    } catch (err) {
      console.log(err);
      setSubmitProfileLoader(false);
      handleDialogClose();
      dialogController({
        open: true,
        content: err?.response?.data?.message || 'Try again in sometime',
        contentType: null,
      });
    }
  };

  const openProfileForm = () => {
    // dialogController({
    //   open: true,
    //   contentType: 'profileForm',
    //   maxWidth: 'sm',
    //   dialogAction: null,
    //   // disableBackdropClick: true,
    // });
    dialogController({
      open: false,
      content: null,
      contentType: null,
      disableBackdropClick: true,
    });
    setProfileForm({
      class: 'profileForm',
      enable: true,
      contentType: 'profileForm',
    });
  };
  const getRecords = async function () {
    let userToken = Ls.get('userToken');
    setRecordLoader(true);
    try {
      let recordsRes = await ServiceCall.get('/patient/records', {
        pt_token: userToken,
      });
      let recordObject = [];
      if (recordsRes.data.medical_records) {
        recordObject = recordsRes.data.medical_records.map((item) => {
          let itemObject = { ...item, downloadLoader: false };
          return itemObject;
          // return (item['downloadLoader'] = false);
        });

        setRecordData({
          recordObject: recordObject,
          lastUpdated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });
      } else {
        setRecordData(recordsRes.data);
      }
      setRecordLoader(false);
    } catch (err) {
      setRecordLoader(false);
      console.log(err);
    }
  };

  const uploadRecords = (files) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');
    myHeaders.append('pt_token', Ls.get('userToken'));
    console.log(files[0].name);
    var formdata = new FormData();
    // formdata.append("medical_record", fileInput.files[0], "file");
    // for (const file of files) {
    formdata.append('medical_record', files[0], files[0].name); // appending every file to formdata
    // }
    // console.log(formdata);
    return new Promise((resolve, reject) =>
      ServiceCall.post('/patient/records', formdata, {
        pt_token: Ls.get('userToken'),
        'Content-Type': 'multipart/form-data',
      })
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        })
    );
  };
  const downloadRecord = async (fileName, index) => {
    let userToken = Ls.get('userToken');
    let url = `/patient/get/record/${fileName}`;

    setDownloadLoader(index);
    apiCall({
      url,
      method: 'get',
      responseType: 'blob',
      headers: {
        pt_token: userToken,
      },
    })
      .then((res) => {
        setDownloadLoader('');
        var a = document.createElement('a');

        var url = window.URL.createObjectURL(res.data);
        a.href = url;
        a.download = fileName;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
        setDownloadLoader('');
      });
  };
  const sharedRecord = async (fileName, index, shareWith) => {
    let userToken = Ls.get('userToken');
    let url = `/patient/record/share/${shareWith}/${fileName}`;

    setDownloadLoader(index);
    apiCall({
      url,
      method: 'put',
      headers: {
        pt_token: userToken,
      },
    })
      .then((res) => {
        setDownloadLoader('');
        getRecords();
      })
      .catch((err) => {
        console.log(err);
        setDownloadLoader('');
      });
  };
  const deleteRecord = async (fileName) => {
    // patient/del/record/testlabreport_10302021124148.jpg

    return new Promise((resolve, reject) =>
      apiCall({
        url: `/patient/del/record/${fileName}`,
        method: 'DELETE',
        headers: {
          pt_token: Ls.get('userToken'),
        },
      })
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
    );
  };
  // get prescription on call
  const getNotes = (appointment_id) => {
    let userToken = Ls.get('userToken');
    // setOverlayLoading('loading');
    setNotesLoader(true);
    return new Promise((resolve, reject) => {
      ServiceCall.get(`/patient/prescription/${appointment_id}`, {
        pt_token: userToken,
      })
        .then((res) => {
          // setAppointmentLoader('completed');
          // setOverlayLoading('completed');
          setNotesLoader(false);
          console.log(res.data);
          let data = res.data;
          let helperObject = {};
          Object.keys(data).map((item) => {
            helperObject[item] = JSON.parse(data[item]);
          });
          // setReport(helperObject);
          resolve(helperObject);
          // setAppointmentList(res.data.appointments ? res.data.appointments : []);
        })
        .catch((err) => {
          // setAppointmentLoader('error');
          // setOverlayLoading('error');
          setNotesLoader(false);
          console.log(err);
          reject(err);
        });
    });
  };

  const initialRouteCheck = function () {
    if (localStorage.getItem('loadedInsideIframe') === 'false') {
      // Ls.flush();

      setHospitalLoader(true);
      if (!query.get('hosp_id') || !query.get('mode')) {
        setHospitalError({
          hasError: true,
          errorType: 'missing',
        });

        dialogController({
          open: true,
          content: (() => (
            <div className="text-center">
              <h3>Please provide hospital id and environment mode</h3>
            </div>
          ))(),
          dialogAction: null,
          maxWidth: 'sm',
          disableBackdropClick: true,
        });
      } else {
        let hospital_id = getLocal('hosp_id');
        let environment = getLocal('mode');
        query.forEach(function (value, key) {
          if (
            key === 'hosp_id' &&
            localStorage.getItem('hosp_id') !== query.get('hosp_id')
          ) {
            localStorage.removeItem('hosp_id');
            console.log('no match');
            hospital_id = value;
          }
          if (
            key === 'mode' &&
            localStorage.getItem('mode') !== query.get('mode')
          ) {
            localStorage.removeItem('mode');
            environment = value;
          }

          localStorage.setItem(key, value);
        });
        setHospitalError({
          hasError: false,
          errorType: '',
        });
        getHospitalDetails(hospital_id);
      }
    } else {
      setHospitalError({
        hasError: false,
        errorType: '',
      });
      getHospitalDetails();
    }
  };

  const getHospitalDetails = function (hospId) {
    return new Promise((resolve, reject) => {
      setHospitalLoader(true);
      let id = `/` + `${hospId ? hospId : getLocal('hosp_id')}`;
      ServiceCall.getv2(OPD?.headerpro, id)
        .then((res) => {
          setHospitalDetails(res.data);
          setHospitalLoader(false);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          setHospitalError({
            hasError: true,
            errorType: 'missing',
          });
          setHospitalLoader(true);

          if (err.response) {
            dialogController({
              open: true,
              content: (() => (
                <div className="text-center">
                  <h3>
                    {err.response.data.message
                      ? err.response.data.message
                      : 'Hospital details not found'}
                  </h3>
                </div>
              ))(),
              dialogAction: null,
              maxWidth: 'sm',
              disableBackdropClick: true,
            });
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log('Error', err.message);
          }
          console.log(err.config);
          reject(err);
        });
    });
  };
  const getReceipts = () => {
    setReceiptLoader('loading');
    let userToken = Ls.get('userToken');
    let url = `/patient/receipts`;
    ServiceCall.get(url, { pt_token: userToken })
      .then((res) => {
        setReceipts({
          receiptData: res.data.reciepts,
          lastUpdated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });
        setReceiptLoader('');
      })
      .catch((err) => {
        // setAppointmentLoader('');
        setReceiptLoader('error');
        if (err.response) {
          //   setApiError('Failed with response ' + err.response.data);
        } else if (err.request) {
          //   setApiError('Failed request ' + err);
        } else {
          //   setApiError('Failed in general ' + err);
        }
      });
  };
  const submitBioMarkerData = (bioMarkerData) => {
    let userToken = Ls.get('userToken');
    return new Promise((resolve, reject) => {
      ServiceCall.post(`/patient/bio/markers`, bioMarkerData, {
        pt_token: userToken,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          // setAppointmentLoader('error');
          // setOverlayLoading('error');
          setNotesLoader(false);
          console.log(err);
          reject(err);
        });
    });
  };
  const getMonitorData = () => {
    setMonitorDataLoader('loading');
    ServiceCall.get('/patient/bio/markers', {
      pt_token: Ls.get('userToken'),
    })
      .then((res) => {
        let monitorData = res.data.bio_markers;
        setMonitorDataLoader('loaded');
        // console.log(monitorData);
        if (monitorData.length > 0) {
          let finalObject = {
            body_temp_fr: { labels: [], datasets: [] },
            blood_pressure: { labels: [], datasets: [] },
            heart_rate: { labels: [], datasets: [] },
            spo2_level: { labels: [], datasets: [] },
          };
          let createHelperArr = { labels: [], dataSet: {} };
          for (let i = 0; i < monitorData.length; i++) {
            let currentItem = monitorData[i];
            // console.log(currentItem);
            Object.keys(currentItem).map((item) => {
              // let helperObject =
              if (item === 'mark_time') {
                if (!createHelperArr['labels']) {
                  createHelperArr['labels'] = [];
                }
                currentItem[item] = currentItem[item].split(' ');
                createHelperArr['labels'].push(currentItem[item][0]);
              } else {
                if (!createHelperArr.dataSet[item]) {
                  createHelperArr.dataSet[item] = {
                    label: keymap[item].name,
                    containerLabel: keymap[item].containerLabel || '',
                    data: [],
                    borderColor: keymap[item].borderColor,
                    backgroundColor: keymap[item].borderColor,
                  };
                }
                createHelperArr.dataSet[item].data.push(currentItem[item]);
              }
              return item;
            });
          }
          // console.log(createHelperArr);
          Object.keys(createHelperArr.dataSet).map((item) => {
            // console.log(item);
            if (
              item === 'dias_blood_pressure' ||
              item === 'syst_blood_pressure'
            ) {
              finalObject['blood_pressure']['datasets'].push(
                createHelperArr.dataSet[item]
              );

              finalObject['blood_pressure']['labels'] = createHelperArr.labels;
            } else {
              finalObject[item]['datasets'].push(createHelperArr.dataSet[item]);
              finalObject[item]['labels'] = createHelperArr.labels;
            }
          });
          // finalObject.labels = createHelperArr.labels;
          // window.copy(finalObject);
          setMonitorData({
            monitorObject: finalObject,
            lastUpdated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          });
        }
      })
      .catch((err) => {
        setMonitorDataLoader('loaded');
        console.log(err);
      });
  };
  const downloadPrescription = (fileName) => {
    setPreTemplateLoader(true);
    return new Promise((resolve, reject) => {
      let userToken = Ls.get('userToken');
      let url = `/patient/get/record/${fileName}`;

      // setDownloadLoader(index);
      apiCall({
        url,
        method: 'get',
        responseType: 'blob',
        headers: {
          pt_token: userToken,
        },
      })
        .then((res) => {
          console.log(res.data);
          setPreTemplateLoader(false);

          resolve(res.data);
        })
        .catch((err) => {
          setPreTemplateLoader(false);
          reject(err);
        });
    });
  };

  const checkNextAvailableSlot = async () => {
    setOverlayLoading('loading');
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');
    try {
      let url = `/${Ls.get('hosp_id')}/${slectedAppointment?.doc?.doc_id}`;
      // Include profile_id in the URL if the user is logged in
      if (isLoggedIn()) {
        url += `/${slectedAppointment?.user?.profile_id}`;
      }
      url += `?lang=${langParam ? langParam : 'en'}`;

      
      const serviceCallOptions = {};

      // Include pt_token and pt_key in the serviceCallOptions if the user is logged in
      if (isLoggedIn()) {
        serviceCallOptions.pt_token = Ls.get('pt_token');
        serviceCallOptions.pt_key = Ls.get('pt_key');
      }
      const slots = await ServiceCall.getv2(
        OPD?.fetchNextAvailableSlog,
        url,
        serviceCallOptions
      );
      if (slots) {
        setOverlayLoading('completed');
        setSelectedAppointment({
          ...slectedAppointment,
          nextslot: slots?.data,
        });
      }
    } catch (err) {
      setState({
        msg:
          err?.response?.data?.message ||
          'Server is not working please try after sometime',
        type: 'error',
        timer: null,
        vertical: 'top',
        horizontal: 'center',
      });
    } finally {
      setOverlayLoading('completed');
    }
  };

  const followupdetail = async () => {
    setOverlayLoading('loading');
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get('lang');

    try {
      let url = `/${Ls.get('hosp_id')}/${slectedAppointment?.doc?.doc_id}/${
        slectedAppointment?.user?.profile_id
      }?lang=${langParam ? langParam : 'en'}`;

      const bookfollow = await ServiceCall.getv2(OPD?.fetchfollowup, url, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (bookfollow) {
        setOverlayLoading('completed');
        setBookflw({
          ...bookflw,
          nextslot: bookfollow?.data,
        });
      }
    } catch (err) {}
  };

  const initiateAppointment = async (opd, url, data) => {
    setOverlayLoading('loading');
    try {
      let bookedSlots = await ServiceCall.postv2(opd, url, data, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (bookedSlots) {
        setOverlayLoading('completed');
        setSelectedAppointment({
          ...slectedAppointment,
          bookedSlot: bookedSlots?.data,
        });
        return bookedSlots?.data;
      }
    } catch (err) {
      setState({
        msg:
          err?.response?.data?.message ||
          'Server is not working please try after sometime',
        type: 'error',
        timer: null,
        vertical: 'top',
        horizontal: 'center',
      });
    } finally {
      setOverlayLoading('completed');
    }
  };

  const raiseOrderAppointment = async (opd, url, data) => {
    try {
      const raiseOrder = await ServiceCall.postv2(opd, url, data, {
        pt_token: Ls.get('pt_token'),
        pt_key: Ls.get('pt_key'),
      });
      if (raiseOrder) {
        setSelectedAppointment({
          ...slectedAppointment,
          raiseOrder: raiseOrder?.data,
        });
        return raiseOrder?.data;
      }
    } catch (err) {
      console.log(err);
      setCheckoutLoader(false);
      setState({
        msg:
          err?.response?.data?.message ||
          'Server is not working please try after sometime',
        type: 'error',
        timer: null,
        vertical: 'top',
        horizontal: 'center',
      });
    }
  };

  const fetchOnline = async () => {
    try {
      const online = await ServiceCall.getv2(
        OPD.fetchOline,
        `/${slectedAppointment?.bookedSlot?.appointment_id}`,
        {
          pt_token: Ls.get('pt_token'),
          pt_key: Ls.get('pt_key'),
        }
      );
      if (online) {
        setSelectedAppointment({
          ...slectedAppointment,
          fetchOrder: online?.data,
        });
        return online?.data;
      }
    } catch (err) {
      setCheckoutLoader(false);
      setState({
        msg:
          err?.response?.data?.message ||
          'Server is not working please try after sometime',
        type: 'error',
        timer: null,
        vertical: 'top',
        horizontal: 'center',
      });
    }
  };

  function dateFormate(date, type = '') {
    const formate = dayjs(date).format(`Do MMM, YYYY ${type}`);
    return formate;
  }

  function timeDifference(appointmentItem) {
    // date1.diff(date2)
    //appointment_date, end_time
    // console.log(appointmentItem);
    let date1 = dayjs();
    let date2 = dayjs(
      `${appointmentItem.appointment_date} ${appointmentItem.end_time}`
    );

    return date1.diff(date2) < 0;
  }
  const contextOption = {
    getAppointment,
    setToggleAppointmentLists,
    toggleAppointmentLists,
    appointmentList,
    dialogController,
    handleDialogClose,
    appointmentLoader,
    appDialog,
    overlayLoading,
    getRecords,
    recordData,
    raiseOrderAppointment,
    uploadRecords,
    getProfile,
    logOutCall,
    openProfileForm,
    submitProfile,
    submitProfileLoader,
    profileData,
    setProfileData,
    recordLoader,
    setRecordLoader,
    downloadRecord,
    getNotes,
    notesLoader,
    deleteRecord,
    getHospitalDetails,
    hospitalDetails,
    getReceipts,
    receiptLoader,
    setReceiptLoader,
    receipts,
    setReceipts,
    setDownloadLoader,
    downloadLoader,
    sharedRecord,
    callActive,
    setCallActive,
    hospitalError,
    hospitalLoader,
    submitBioMarkerData,
    getMonitorData,
    monitorData,
    dateFormate,
    monitorDataLoader,
    setJoinActive,
    joinActive,
    currentTab,
    Encrypt,
    getProfileById,
    setCurrentTab,
    globalTimer,
    downloadPrescription,
    preTemplateLoader,
    appointmentLastUpdate,
    checkDisable,
    profileForm,
    profileLoader,
    timeDifference,
    setState,
    state,
    setDynamicImageStore,
    DynamicImageStore,
    setSelectedAppointment,
    slectedAppointment,
    setProfileForm,
    setOverlayLoading,
    initiateAppointment,
    setCheckoutLoader,
    fetchOnline,
    checkoutLoader,
    defaultAppointmentData,
    checkNextAvailableSlot,
    followupdetail,
    updateProfile,
  };
  return (
    <PatientpageContext.Provider value={contextOption}>
      {props.children}
    </PatientpageContext.Provider>
  );
}
