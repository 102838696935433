import React, { useContext, useEffect } from 'react';
import { PatientpageContext } from '../context/GlobalContext';
import { useHistory } from 'react-router-dom';
import Button from '../Tools/Button';
import { OPD } from '../calls/api';
import CircularProgress from '@mui/material/CircularProgress';
import { razorpayCall } from '../utility/utility';
import { ServiceCall } from '../utility/service-call';
import congratsImage from '../static/images/payment-completed.png';
import { verifyMessage } from '../utility/config';
import { Ls } from '../utility/utility';

const CheckoutScreenNew = ({ isOnline, isWalkin, toggleAppointment }) => {
  const patientContext = useContext(PatientpageContext);
  const history = useHistory();
  const raiseOrder = patientContext?.slectedAppointment?.raiseOrder;
  const totalDiscount =
    Number(
      patientContext?.slectedAppointment?.bookedSlot?.cash_payment_amount
    ) -
    Number(
      patientContext?.slectedAppointment?.bookedSlot?.online_payment_amount
    );
  useEffect(() => {
    return () => {
      patientContext?.setCheckoutLoader(false);
      patientContext?.setSelectedAppointment({
        ...patientContext?.slectedAppointment,
        raiseOrder: '',
        fetchOrder: '',
        nextslot:'',
      });
    };
  }, []);

  const afterPayment = async (appointmentId, paymentObject, errorObject) => {
    if (errorObject?.hasError) {
      patientContext?.dialogController({
        open: true,
        content: (() => errorObject?.errorMessage)(),
        checkLoginPage: false,
        promptWindow: true,
        dialogAction: (() => (
          <>
            <Button
              handleClick={() => {
                patientContext.handleDialogClose();
              }}
              style={{ background: 'red !important' }}
              className="mr-2"
              containerClass="m-auto "
            >
              CLOSE
            </Button>
          </>
        ))(),
        maxWidth: 'sm',
        disableBackdropClick: true,
      });
    } else {
      try {
        patientContext?.setOverlayLoading('loading');
        const VerifyPayment = await ServiceCall.postv2(
          OPD?.verifyPayment,
          `/${appointmentId}`,
          paymentObject,
          {
            pt_token: Ls.get('pt_token'),
            pt_key: Ls.get('pt_key'),
          }
        );

        if (VerifyPayment) {
          patientContext?.setOverlayLoading('completed');
          patientContext?.dialogController({
            open: true,
            content: (() => (
              <div className="appointment__screen__verify">
                {VerifyPayment?.data?.message?.includes(verifyMessage) ? (
                  <>
                    <div className="appointment__screen__verify__image">
                      <img src={congratsImage} alt="congrats" />
                    </div>
                    <div className="appointment__screen__verify__text">
                      {VerifyPayment?.data?.message || (
                        <>
                          Your appointment is booked successfully with
                          <strong>
                            {
                              patientContext?.slectedAppointment?.doc
                                ?.doctor_name
                            }
                          </strong>
                          . Your expected waiting time is{' '}
                          <strong>15 Mins</strong>
                        </>
                      )}
                    </div>
                    <div className="appointment__screen__verify__text">
                      We will notify you once{' '}
                      {patientContext?.slectedAppointment?.doc?.doctor_name} is
                      ready to meet.
                    </div>
                  </>
                ) : (
                  <>{VerifyPayment?.data?.message}</>
                )}
              </div>
            ))(),
            checkLoginPage: false,
            promptWindow: true,
            dialogAction: (() => (
              <>
                <Button
                  handleClick={() => {
                    patientContext.handleDialogClose();
                    // toggleAppointment();
                    history.push({
                      pathname: '/order-placed',
                      search: history?.location?.search,
                    });
                  }}
                  className="mr-2"
                  containerClass="m-auto appointment__screen__verify"
                >
                  CLOSE
                </Button>
              </>
            ))(),
            maxWidth: 'sm',
            disableBackdropClick: true,
          });
        }
      } catch (err) {
        patientContext?.setState({
          msg:
            err?.response?.data?.message ||
            'Server is not working please try after sometime',
          type: 'error',
          timer: null,
          vertical: 'top',
          horizontal: 'center',
        });
        patientContext?.setOverlayLoading('completed');
      }
    }
  };

  const handleGenerateOrder = async () => {
    patientContext?.setCheckoutLoader(true);
    let url = `/${patientContext?.slectedAppointment?.payment_type}/${patientContext?.slectedAppointment?.bookedSlot?.appointment_id}/${patientContext?.slectedAppointment?.user?.profile_id}`;
    patientContext
      ?.raiseOrderAppointment(
        isWalkin ? OPD?.raiseOrderWalkin : OPD?.raiseOrder,
        url,
        ''
      )
      .then((raiseOrder) => {
        if (!isOnline && raiseOrder?.order_id) {
          patientContext?.setCheckoutLoader(false);
          patientContext?.dialogController({
            open: true,
            content: (() => (
              <div className="text-center">
                <h4>
                  {raiseOrder?.message || (
                    <>
                      Please proceed with cash/card payment. Token will be
                      generated and sent to you after the payment.
                    </>
                  )}
                </h4>
              </div>
            ))(),
            checkLoginPage: false,
            promptWindow: true,
            dialogAction: (() => (
              <>
                <Button
                  handleClick={() => {
                    patientContext.handleDialogClose();
                    // toggleAppointment();
                    history.push({
                      pathname: '/order-placed',
                      search: history?.location?.search,
                    });
                  }}
                  className="mr-2"
                  containerClass="m-auto appointment__screen__verify"
                >
                  Close
                </Button>
              </>
            ))(),
            maxWidth: 'sm',
            disableBackdropClick: true,
          });
        } else {
          patientContext?.fetchOnline().then((response) => {
            if (response?.order?.order_id && raiseOrder?.order_id) {
              patientContext?.setCheckoutLoader(false);
              razorpayCall(
                patientContext?.slectedAppointment?.bookedSlot?.appointment_id,
                afterPayment,
                response?.order,
                patientContext?.slectedAppointment?.user,
                patientContext?.hospitalDetails
              );
            }
          });
        }
      });
  };
  return (
    <div className="appointment__screen__checkout">
      <div className="appointment__screen__details">Bill Details</div>
      <div className="appointment__screen__table">
        <div className="appointment__screen__table__row">
          <div>Consultation Fee</div>
          <div>
            {
              patientContext?.slectedAppointment?.bookedSlot
                ?.online_payment_currency
            }{' '}
            {
              patientContext?.slectedAppointment?.bookedSlot
                ?.cash_payment_amount
            }
          </div>
        </div>
        <div className="appointment__screen__table__row">
          <div>Booking Fee</div>
          <div>
            <span>
              {
                patientContext?.slectedAppointment?.bookedSlot
                  ?.online_payment_currency
              }{' '}
              50
            </span>
            <span>Free</span>
          </div>
        </div>
        {patientContext?.slectedAppointment?.payment_type !== 'cash' &&
        !!totalDiscount ? (
          <div className="appointment__screen__table__row">
            <div>Online Payment Discount</div>
            <div>
              {
                patientContext?.slectedAppointment?.bookedSlot
                  ?.online_payment_currency
              }
              <strong>({totalDiscount})</strong>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="appointment__screen__checkout__payment appointment__screen__table__row">
        <div>Total payable</div>
        <div>
          {
            patientContext?.slectedAppointment?.bookedSlot
              ?.online_payment_currency
          }
          <span>
            <span>
              {!isOnline
                ? patientContext?.slectedAppointment?.bookedSlot
                    ?.cash_payment_amount
                : patientContext?.slectedAppointment?.bookedSlot
                    ?.online_payment_amount}
            </span>
            <div>{isOnline ? '(+ PG Charges *)' : ''}</div>
          </span>
        </div>
      </div>
      <div className="appointment__screen__checkout__bottom">
        {isOnline ? (
          <div className="appointment__screen__checkout__bottom__text">
            *A small amount may be charged by payment gateway, as per selected
            payment method
          </div>
        ) : (
          <></>
        )}
        <Button
          disabled={patientContext?.checkoutLoader}
          containerClass={`${
            patientContext?.checkoutLoader ? 'static_disable ' : ' '
          }`}
          handleClick={handleGenerateOrder}
        >
          {patientContext?.checkoutLoader ? (
            <CircularProgress size="20px" sx={{ color: '#199a8e' }} />
          ) : (
            <>Pay Now</>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutScreenNew;
