import React from 'react'

function MemoOnline() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path fill="url(#pattern0)" d="M0 0H48V48H0z"></path>
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02083)" xlinkHref="#image0_6_10354"></use>
        </pattern>
        <image
          id="image0_6_10354"
          width="48"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAC3UlEQVRoge2ZS0hUURzGv6ONhgaR0Aukp4taRKRtq020CdEyaRFFFNSqggjaFEQRaEbgUqKCclUKE+1ya6ssKsoS01XZxiiYUHzkr8WdkeE293Gm69wJ7g8GLuee853vu5zzv4c7UkJCQkKcmDgmBaokHZLUIqlRUn321hdJryWlJaWNMbNx+PMFaAPGCOYzcDhuv4sAlcDtEMbddAEVcftXkeZzdMZtvi2EyUngsc/91rjMV+GsZy+GgDNADbDOp984zuYveYCjHobSwE5XX78AAO1u/VJsjhaP9j5jzFvLp/qXVikCNHm0bwe6JY1baO2OwI8dQCZgWUzl9Q1aQhm3fvz11Y4Fd0MpAkwspVYpAryKUGvI3VCKAOkItZ5GqBUOIAWMRrCJxwqV3GWWZlZI2irpnTGGMGOMMXPAZUn9Hl1SQE/2usZH6qL18RpYAzQDHcAgMJN9GiPABaDWQqsroET60RF2kgbgJHAP+BRC+DtwC9gYQrsi29eWTsIcp4GJIsRzzAP9wL4Q87TivydyjAJex5CCwlHxBjgFLPeZKwW0A73AR5y3dSZ73QscAVKhzUccIMcPoBvYYGWkWJYgQI7fwDNg//8aIJ+XwHGg2tJbLbC3HALkuBnS+FrgBk61ywB15RBgloB9AWzB2T9TrrFXyyHAIx8PjcBDnLJciEm8Xp4lsw+7XPManLf9QMjx5+IM8DxvvmrgNDBsqdGX79vqMBcB08AqSWclnZe03mLsiKQrch0KFz/uAqFOlxEwI8mmjH6VdF3SfWPMvPtmUIBhSU+yP0lql3RMUoOFgWLJSLojqdMYMx3YO2+NfQCuAdt8+jbhlLlv/7gnCjED9ACrreICl4DNlmMqgQPAA+DnPxqfA+4C9cEzLwE4VaUZp47/sjQ/AOyIxXghgJXACZxD3KyP8RfAnrj9+gLU4Xx9HgQWssbfU+BDbdkDbAIOUg7/uCQkJJQHfwD8h29j3T5FGQAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
  );
}

export default MemoOnline