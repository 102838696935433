import React, { useState } from "react";
import { Slider, Box, Grid, Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import Overlay from "./Overlay";

export default function BioMarker({ patientContext }) {
  const marks = {
    heartBeat: {
      max: 200,
      min: 50,
      minValue: 100,
      name: "heart_rate",
      marks: [
        {
          value: 50,
          label: "50 BPM",
        },
        {
          value: 200,
          label: "200 BPM",
        },
      ],
    },
    bodyTemp: {
      max: 105,
      min: 95,
      minValue: 98,
      name: "body_temp_fr",
      marks: [
        {
          value: 95,
          label: "95 °F",
        },
        {
          value: 105,
          label: "105 °F",
        },
      ],
    },
    systolicBp: {
      max: 150,
      min: 120,
      minValue: 120,
      name: "syst_blood_pressure",
      marks: [
        {
          value: 120,
          label: "120 mm Hg",
        },
        {
          value: 150,
          label: "150 mm Hg",
        },
      ],
    },
    disystolicBp: {
      max: 100,
      min: 60,
      minValue: 80,
      name: "dias_blood_pressure",
      marks: [
        {
          value: 60,
          label: "60 mm Hg",
        },
        {
          value: 100,
          label: "100 mm Hg",
        },
      ],
    },
    spo2: {
      max: 100,
      min: 60,
      minValue: 95,
      name: "spo2_level",
      marks: [
        {
          value: 60,
          label: "60%",
        },
        {
          value: 100,
          label: "100%",
        },
      ],
    },
  };
  const [postMarkerLoader, setMarkerLoader] = useState('');
  const [successContent, setSuccessContent] = useState('');
  const [formVal, setFormVal] = useState({
    heart_rate: 100,
    body_temp_fr: 98,
    syst_blood_pressure: 120,
    dias_blood_pressure: 80,
    spo2_level: 95,
  });
  const changeVal = (e) => {
    let { value, name } = e.target;
    let formData = { ...formVal };
    console.log(name);
    formData[name] = value;
    setFormVal(formData);
  };
  const postBiomarker = () => {
    setMarkerLoader('loading');
    patientContext.submitBioMarkerData(formVal).then(res=>{
      console.log(res);
      setMarkerLoader('success');
      // patientContext.handleDialogClose();   
      setSuccessContent(res.message) 

    }).catch(err=>{
      console.log(err);
      setMarkerLoader('error');
    });
  };
  return (
    <Box sx={{position:'relative'}} className="px-2">
      {postMarkerLoader==='loading' && <Overlay>
        <CircularProgress size='20px' color='success' />
      </Overlay>}
      {postMarkerLoader==='success'?<Grid item xs={12} className='text-center'>{successContent}</Grid>:
      <Grid container className='biomarkerForm font-12 px-4 px-sm-0'>
        <Grid item xs={12} className='text-center'>
          <h2>Bio Marker</h2>
        </Grid>
        <Grid item xs={12}>
          <h3>{dayjs().format("DD/MM/YYYY hh:mm A")}</h3>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor={marks.heartBeat.name} className='pr-3 pt-2'>
            Heart Rate(BPM)
          </label>
        </Grid>
        <Grid item xs={12} className='pb-2'>
          <Slider
            id={marks.heartBeat.name}
            name={marks.heartBeat.name}
            size='small'
            marks={marks.heartBeat.marks}
            min={marks.heartBeat.min}
            max={marks.heartBeat.max}
            defaultValue={marks.heartBeat.minValue}
            aria-label='Small'
            valueLabelDisplay='auto'
            onChange={changeVal}
          />
        </Grid>

        <Grid item xs={12} className='pt-2'>
          <label htmlFor={marks.bodyTemp.name} className='pr-3 pt-2'>
            Body Temperature:
          </label>
        </Grid>
        <Grid item xs={12}>
          <Slider
            id={marks.bodyTemp.name}
            name={marks.bodyTemp.name}
            size='small'
            marks={marks.bodyTemp.marks}
            min={marks.bodyTemp.min}
            max={marks.bodyTemp.max}
            step={0.1}
            defaultValue={marks.bodyTemp.minValue}
            aria-label='Small'
            valueLabelDisplay='auto'
            onChange={changeVal}
          />
        </Grid>
        <Grid item container xs={12} sm={6} className='pr-sm-2'>
          <Grid item xs={12} className='pt-2'>
            <label htmlFor={marks.systolicBp.name} className='pt-2'>
              Systolic blood pressure:
            </label>
          </Grid>
          <Grid item xs={12}>
            <Slider
              id={marks.systolicBp.name}
              name={marks.systolicBp.name}
              size='small'
              marks={marks.systolicBp.marks}
              min={marks.systolicBp.min}
              max={marks.systolicBp.max}
              step={1}
              defaultValue={marks.systolicBp.minValue}
              aria-label='Small'
              valueLabelDisplay='auto'
              onChange={changeVal}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} className='pl-sm-2'>
          <Grid item xs={12} className='pt-2'>
            <label htmlFor={marks.disystolicBp.name} className='pt-2'>
              Diastolic blood pressure:
            </label>
          </Grid>
          <Grid item xs={12}>
            <Slider
              id={marks.disystolicBp.name}
              name={marks.disystolicBp.name}
              size='small'
              marks={marks.disystolicBp.marks}
              min={marks.disystolicBp.min}
              max={marks.disystolicBp.max}
              step={1}
              defaultValue={marks.disystolicBp.minValue}
              aria-label='Small'
              valueLabelDisplay='auto'
              onChange={changeVal}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className='pt-2'>
          <label htmlFor={marks.spo2.name} className='pt-2'>
            SPO2 (Blood Oxygen):
          </label>
        </Grid>
        <Grid item xs={12}>
          <Slider
            id={marks.spo2.name}
            name={marks.spo2.name}
            size='small'
            marks={marks.spo2.marks}
            min={marks.spo2.min}
            max={marks.spo2.max}
            step={1}
            defaultValue={marks.spo2.minValue}
            aria-label='Small'
            valueLabelDisplay='auto'
            onChange={changeVal}
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems='center'
          justifyContent='center'
        >
          <Button variant='contained' color='error' onClick={postBiomarker}>
            Save
          </Button>
        </Grid>
      </Grid>}
    </Box>
  );
}
