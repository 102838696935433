import React, { useEffect,useState } from 'react'
import {Modal,Button,Box,Typography} from "@material-ui/core"
import FaqDetails from "./FaqDetails"
import { ServiceCall } from '../utility/service-call';
import { autocompleteClasses } from '@mui/material';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CloseIcon from '@mui/icons-material/Close';
import "../scss/_faq.scss"

function Faq() {

    const [open, setOpen] = React.useState(false);
    const [blogData, setBlogData] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    useEffect(()=>{
        handleData()
        console.log("blog")
    },[open])

    const handleData = async() =>{
      const result = await ServiceCall.blog("")
      if(result){
        setBlogData(result.data)
      }

    }

    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        height:"80vh",
        overflow:"auto"

      };

      const faqClose={
        position:"absolute",
        right:"30px",
        top:"30px",
        cursor:"pointer",
        fontSize:"30px"
  
      }


      const faqButton = {
        color:"#005c74",
        // fontWeight:"bold",
        fontSize:"18px",
        marginBottom:"10px"
        // backgroundColor:"#005c74"
      }


    return (
    <div>
        <Button onClick={handleOpen} style={faqButton}><LiveHelpIcon sx={{marginRight:1}}/><span className='fw-bold'>FAQ</span></Button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={boxStyle} >
          <CloseIcon onClick={handleClose} sx={faqClose}/>
          <h1 className='text-center'>FAQ – Patient Portal</h1>
          <div className='faq_main_container'>
            <FaqDetails blog={blogData}/>
          </div>
        </Box>
        </Modal>
    </div>
  )
}

export default Faq