import './scss/App.scss';
import LogIn from './pages/Patient/Login';
import { ThemeProvider } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import { theme } from './utility/themeSettings';
import NotFound from './pages/not-found/NotFound';
import DetailsPage from './pages/Patient/DetailsPage';
import JoinPage from './pages/Patient/JoinPage';
import ErrorBoundary from './pages/error-boundary/ErrorBoundary';
import GlobalContext from './context/GlobalContext';
import { useEffect } from 'react';
import VerifyBot from './components/VerifyBot';
import HospitalErrorPage from './pages/Patient/HospitalErrorPage';
import ThankuPage from './components/ThankuPage';
import SnackbarAlert from './Tools/SnackbarAlert';
import Home from './pages/Patient/Home';
import PharmacySelection from './pages/Patient/PharmacySelection';
import Ordertest from './pages/Patient/Ordertest';
import Patientpro from './pages/Patient/Patientpro';

function App() {

  useEffect(() => {
  }, []);
  return (
    <div className="wrapper-background">
      <div className="overflow-hidden-app app-screen">
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <GlobalContext>
              {/* <FreshChat> */}
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Home}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={LogIn}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pharmacy`}
                  component={PharmacySelection}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pathology`}
                  component={Ordertest}
                />


              <Route
              exact
              path={`${process.env.PUBLIC_URL}/patientpro`}
              component={Patientpro}
              />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/consultation`}
                  component={() => <DetailsPage pageTitle="Patient Page" />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/consultation/:tabIndex`}
                  component={() => <DetailsPage pageTitle="Patient Page" />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/join/:roomid`}
                  component={() => <JoinPage pageTitle="JoinPage" />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/hospitalError`}
                  component={() => <HospitalErrorPage />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/order-placed`}
                  component={() => <ThankuPage />}
                />

                <Route component={NotFound} />
              </Switch>
              <SnackbarAlert />
              <VerifyBot />
            </GlobalContext>
          </ThemeProvider>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
