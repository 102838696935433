import React, { useContext, useState } from 'react';
import { Grid, Typography, Box, Button, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import * as dayjs from 'dayjs';
import { Ls, checkFutureToday } from '../utility/utility';
import AppointmentListItem from './AppointmentListItem';
import AppointmentScreen from './AppointmentScreen';
import Overlay from './Overlay';
import { AppointmentImages } from '../static/ImageManager';
import AppointmentCart from './AppointmentCart';
import { PatientpageContext } from './../context/GlobalContext';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ButtonStyle from '../Tools/Button';
import { stringTrim } from '../utility/utility';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect } from 'react';
import MemoSearch from '../static/svg-component/MemoSearch.jsx';
import AppointmentScreenNew from './AppointmentScreenNew';
import ProfileForm from './ProfileForm';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function AppointmentTab({
  toggleAppointment,
  showAppointment,
  getAvailableSlots,
  appointmentList,
  classes,
  doctorsList,
  appointMentLoader,
}) {
  const patientContext = useContext(PatientpageContext);
  const [open, setOpen] = useState(false);
  // const [currentAppointmentLength, setCurrentAppointmentLength] = useState(0);
  const [openFollowup, setOpenFollowup] = useState(null);
  const [search, setSearch] = useState('');
  const [showProfileForm, setProfileForm] = useState({
    form: false,
    type: 'new',
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [loginMeetNow, setLoginMeetNow] = useState(false);
  const [showAppointmentbook, setShowAppointmentbook] = useState(false);

  useEffect(() => {
    patientContext?.setSelectedAppointment({
      ...patientContext?.slectedAppointment,
      user:
        patientContext?.slectedAppointment?.user ||
        (Array.isArray(patientContext?.profileData?.profiles) &&
          patientContext?.profileData?.profiles[0]) ||
        '',
    });
  }, [patientContext?.profileData, patientContext?.slectedAppointment.user]);

  useEffect(() => {
    // patientContext.setCurrentTab('');
    if (!!patientContext?.slectedAppointment?.doc) {
      // openAppointmentScreen();
      toggleAppointment();
    }
  }, [patientContext?.slectedAppointment?.doc]);

  //check if pateintContext.profileData is empty or not
  const openAppointmentScreen = (appointment) => {
    if (patientContext.profileData.length !== 0) {
      toggleAppointment();
      // showAppointment(appointment);
    } else {
      patientContext.dialogController({
        open: true,
        content: (() => (
          <div className="text-center">
            <h3>Please update your profile before making an appointment.</h3>
          </div>
        ))(),
        dialogAction: (() => (
          <>
            <Button
              color="error"
              variant="contained"
              onClick={patientContext.openProfileForm}
              className="mr-2"
            >
              Proceed
            </Button>
          </>
        ))(),
        maxWidth: 'sm',
        disableBackdropClick: true,
      });
    }
  };
  const followUpBook = (appoDetails) => {
    toggleAppointment('followup');
    setOpenFollowup(appoDetails);
  };
  function renderAppointmentList(lists, classProps) {
    // console.log('render');
    // patientContext.setJoinActive([]);
    let aa = [];
    for (let i = 0; i < lists.length; i++) {
      let itemClone = lists[i];
      itemClone.sameDay = checkFutureToday(
        new Date(itemClone.appointment_date)
      );

      // if (itemClone.sameDay !== "past") {
      // setCurrentAppointmentLength(oldValue => oldValue + 1);
      aa.push(
        <AppointmentListItem
          key={itemClone.room_id}
          item={itemClone}
          indexNo={i}
          classes={classProps}
          followUpBook={followUpBook}
        />
      );
      // }
    }

    return aa;
  }

  const handlebuttonclick = () => {
    setLoading(true);
    history.push({
      pathname: `/`,
      search: history?.location?.search,
    });
  };

  const isLoggedIn = () => {
    return Ls.get('pt_key') && Ls.get('pt_token');
  };

  useEffect(() => {
    // console.log('1111');
    const queryParams = new URLSearchParams(location.search);
    const redirectFrom = queryParams.get('redirectfrom');
    const btn = queryParams.get('btn');
    // console.log('redirectFrom', redirectFrom);
    // console.log('btn', btn);
    // console.log('isLoggedIn', isLoggedIn());
    if (redirectFrom && btn && btn === 'meet-now' && isLoggedIn()) {
      // console.log('2222');
      toggleAppointment();
      setLoginMeetNow(true);

      queryParams.delete('redirectfrom');
      queryParams.delete('btn');
      history.replace({
        search: queryParams.toString(),
      });
    } else if (
      redirectFrom &&
      btn &&
      btn === 'book-appointment' &&
      isLoggedIn()
    ) {
      // console.log('2222');
      toggleAppointment();
      setLoginMeetNow(true);

      queryParams.delete('redirectfrom');
      queryParams.delete('btn');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history, location]);

  const openAppointmentTab = () => {
    toggleAppointment();
  };

  return (
    <>
      {/* {showProfileForm?.form ? (
        <ProfileForm
          showProfileForm={showProfileForm}
          setProfileForm={setProfileForm}
          heading=" "
        /> */}
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : showProfileForm?.form ? (
        <ProfileForm
          showProfileForm={showProfileForm}
          setProfileForm={setProfileForm}
          heading=" "
        />
      ) : (
        <Box className="px-sm-3">
          {!showAppointment && (
            <>
              {appointmentList.length === 0 ? (
                <img
                  alt="no-appointment-found"
                  style={{ width: '100%' }}
                  src={AppointmentImages?.noAppointmentIcon}
                />
              ) : (
                <Grid
                  container
                  className={`mt-3 position-relative ${
                    showAppointment ? 'd-none' : ''
                  }`}
                >
                  {/* {patientContext.overlayLoading === 'loading' && (
                <Overlay>
                  <CircularProgress size="20px" color="success" />
                </Overlay>
              )} */}

                  {/* <h2>
          <button onClick={handlebuttonclick}>
           <ChevronLeftIcon/>
          </button>
          Home
        </h2> */}

                  {patientContext?.profileData?.profiles &&
                  Array.isArray(patientContext?.profileData?.profiles) ? (
                    <div className="appointment__patient">
                     {(isLoggedIn()  &&<h2>
                        <button onClick={handlebuttonclick}>
                          <ArrowBackIosNewIcon />
                        </button>
                      </h2>
                     )}
                      {/* <h6>Booking Appointment for</h6>


                      <div className="appointment__patient__details">
                        <div className="appointment__patient__details__scroll">
                          {patientContext?.profileData?.profiles?.map(
                            (list) => (
                              <div
                                className={`${patientContext?.slectedAppointment?.user
                                    ?.name === list?.name
                                    ? 'appointment__patient__button__selected'
                                    : ''
                                  } appointment__patient__button`}
                                onClick={() => {
                                  if (
                                    patientContext?.slectedAppointment?.user ===
                                    list
                                  ) {
                                    setProfileForm({
                                      form: true,
                                      type: 'update',
                                    });
                                  } else {
                                    patientContext?.setSelectedAppointment({
                                      ...patientContext?.slectedAppointment,
                                      user: list,
                                    });
                                  }
                                }}
                              >
                                {stringTrim(list?.name, 10)}
                              </div>
                            )
                          )}
                        </div>
                        <div
                          className="appointment__patient__button appointment__patient__add"
                          onClick={() => {
                            setProfileForm({ form: true, type: 'new' });
                          }}
                        >
                          Add +
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <></>
                  )}
                  <h6 className="text-center w-100 mb-3 appointment_text">
                    Select the doctor you wish to consult
                  </h6>
                  {/* {renderAppointmentList(appointmentList, classes)} */}
                  <div className="appointment__search">
                    <input
                      type="text"
                      placeholder="Search for Doctor or Speciality"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <MemoSearch />
                  </div>

                  <div className="appointment__cards">
                    {appointmentList &&
                    Array.isArray(appointmentList?.doctors) ? (
                      appointmentList?.doctors
                        ?.filter(
                          (list) =>
                            list?.doctor_name
                              ?.toLowerCase()
                              ?.includes(search?.trim()?.toLowerCase()) ||
                            list?.speciality
                              ?.toLowerCase()
                              ?.includes(search?.trim()?.toLowerCase())
                        )
                        ?.map((list) => (
                          <AppointmentCart
                            list={list}
                            handleClick={() => {
                              // console.log(
                              //   'select'.patientContext?.slectedAppointment
                              // );
                              // console.log(list);

                              patientContext?.setSelectedAppointment({
                                ...patientContext?.slectedAppointment,
                                doc: list,
                              });
                            }}
                          />
                        ))
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              )}
            </>
          )}

          <Grid container item xs={12} sm={12}>
            {showAppointment ? (
              <AppointmentScreenNew
                toggleAppointment={toggleAppointment}
                loginMeetNow={loginMeetNow}
                setLoginMeetNow={setLoginMeetNow}
                openAppointmentTab={openAppointmentTab} 
              />
            ) : (
              // <AppointmentScreen
              //   doctorsList={doctorsList}
              //   followUp={openFollowup}
              //   toggleAppointment={toggleAppointment}
              // />
              <Box className="d-none justify-content-flex-end w-100">
                {/* <Button
                    variant="contained"
                    size="large"
                    onClick={toggleAppointment}
                    color="primary"
                  >
                    Book Appointment
                  </Button> */}
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}
