import React, { useEffect, useState, useContext, useRef } from 'react';

import {
  Box,
  Stack,
  Switch,
  Paper,
  TextField,
  Grid,
  FormControl,
  Button,
  IconButton,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { red } from '@mui/material/colors';

import { CircularProgress } from '@material-ui/core';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { baseUrl, constants } from '../utility/constants';
import { convertTimeSlot, Ls, razorpayCall } from './../utility/utility';
import { ServiceCall, apiCall } from './../utility/service-call';
import styles from './../styles/appointmentScreensStyle';
import TimeSelectedScreen from './TimeSelectedScreen';
// import { constants } from "../utility/constants";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import { PatientpageContext } from './../context/GlobalContext';
import ImageAvatar from './ImageAvatar';
import CheckoutScreen from './CheckoutScreen';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { method } from 'lodash';
import { margin } from '@mui/system';
const stepsConst = [
  'Chose Type',
  'Select Date',
  'Select Doctor',
  'Select Doctor’s Time Slot',
  'Checkout & Pay',
];
const useStyles = makeStyles(styles, { index: 1 });
export default function AppointmentScreen({
  toggleAppointment,
  doctorsList,
  followUp,
}) {
  const patientContext = useContext(PatientpageContext);
  const classes = useStyles();
  const [appointmentType, setAppointmentType] = useState('');
  const [paymentType, setPaymentType] = useState('online');
  const [doctorLoader, setDoctorLoader] = useState(false);
  const [bookAppLoader, setBookAppLoader] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [apiError, setApiError] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [appointments, setAppointment] = useState([]);
  const [appointmentLoader, setAppointmentLoader] = useState('');
  const initialLoad = useRef(true);
  const [appointMentSlotMsg, setAppointMentSlotMsg] = useState(
    'Select doctor to get available slots'
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(stepsConst);
  // const [bookFollowupLoader, setBookFollowupLoader] = useState(false);
  // const [successDialog, setSuccessDialog] = useState(defaultDialog);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  // const { 'Registered Doctors': RegisteredDoctor } = doctorsList;

  const [selectedTime, setSelectedTime] = React.useState({});

  // useEffect(() => {
  //   // getAvailableDoctor();
  // }, []);

  // useEffect(() => {
  //   getAvailableSlots(undefined, doctorId);
  // }, [selectedDate])

  const handleChange = (value) => {
    setSelectedTime(value);
    if (followUp) {
      // {{url}}/test/doctor/followup/appointment/6eba8a1f-0e22-44ba-ba0d-86f2c3a5
    } else {
      checkAppointmentType();
    }
  };
  // const handleDialogClose = () => {
  //   setSuccessDialog(defaultDialog);
  // };
  const getAvailableDoctor = () => {
    setDoctorLoader(true);
    initialLoad.current = false;
    ServiceCall.get(
      `/doctor/available/${constants.hospitalId('hosp_id')}/${
        appointmentType === 'online' ? 'online' : 'in-person'
      }/${dayjs(selectedDate).format('YYYY-MM-DD')}`
    )
      .then((res) => {
        setDoctorLoader(false);
        setDoctorList(res.data[constants.registeredDoctor]);
        if (res.data[constants.registeredDoctor].length > 0) {
          setCurrentStep((oldValue) => oldValue + 1);
        }
      })
      .catch((err) => {
        setDoctorLoader(false);
        if (err.response) {
          setApiError('Failed with response ' + err.response.data);
        } else if (err.request) {
          setApiError('Failed request ' + err);
        } else {
          setApiError('Failed in general ' + err);
        }
      });
  };

  const resetStates = () => {
    setSelectedTime({});
  };
  useEffect(() => {
    if (!doctorId) {
      setAppointment([]);
    }
    if (followUp && steps.length === 5) {
      setSteps((oldValue) => {
        oldValue.pop();
        return oldValue;
      });
    }
  }, [doctorId, followUp]);

  const callBookFollowUp = () => {
    setBookAppLoader(true);
    let url = `/doctor/followup/appointment/${followUp.appointment_id}`;
    apiCall({
      url,
      method: 'POST',
      headers: {
        pt_token: Ls.get('userToken'),
      },
      data: {
        appointment_date: dayjs(selectedTime.schedule_date).format(
          'YYYY-MM-DD'
        ),
        time_alloted: selectedTime.compareTime,
        is_inperson:
          appointmentType && appointmentType === 'inperson' ? 'True' : 'False',
        slot_duration: selectedTime.slot_duration,
        end_time: selectedTime.end_time.replace(':', '.'),
      },
    })
      .then((res) => {
        setBookAppLoader(false);
        runAfterPayment(res.data.message);
      })
      .catch((err) => {
        setBookAppLoader(false);
        toggleAppointment();
        if (err.response) {
          setApiError('Failed with response ' + err.response.data);
          if (err.response.status === 401 || err.response.status === 400) {
            patientContext.dialogController({
              open: true,
              content: err.response.data.message,
              maxWidth: 'sm',
            });
          }
        } else if (err.request) {
          setApiError('Failed request ' + err);
          patientContext.dialogController({
            open: true,
            content: 'Failed request ' + err,
            maxWidth: 'sm',
          });
        } else {
          setApiError('Failed in general ' + err);
          patientContext.dialogController({
            open: true,
            content: 'Failed in general ' + err,
            maxWidth: 'sm',
          });
        }
      });
  };

  const checkAppointmentType = () => {
    if (appointmentType === 'inperson') {
      patientContext.dialogController({
        open: true,
        content: (
          <div className="text-center">
            <h3>How you would like to make the payment for the appointment?</h3>
            <h6>Appointment will be confirmed after successful payment.</h6>
            <Grid container spacing={2} className="mt-n2 text-center">
              <Grid item xs={12} sm={6}>
                <div className="pb-sm-3 pb-1">
                  <Box sx={{ color: red['700'] }} className="font-12 pb-2">
                    {' '}
                    {`(After ${selectedDoctor[0].online_discount}% Discount)`}
                  </Box>
                  <Chip
                    label={`Rs. ${
                      parseFloat(selectedDoctor[0].consult_charge) +
                      parseFloat(selectedDoctor[0].online_discount_amount)
                    }`}
                    color="error"
                    variant="outlined"
                  />
                </div>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setPaymentType('online');
                    patientContext.handleDialogClose();
                    displayCheckoutScreen(selectedDoctor, 'online');
                  }}
                >
                  Pay Online
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className="text-center">
                <div className="pb-sm-3 pb-1 text-center">
                  <Box
                    sx={{
                      color: red['700'],
                      overflow: 'hidden',
                      textIndent: '-999999px',
                    }}
                    className="font-12 pb-2"
                  >
                    cash payment
                  </Box>
                  <Chip
                    className={`${classes.blackBtn}`}
                    label={`Rs. ${parseFloat(
                      selectedDoctor[0].consult_charge
                    )}`}
                    variant="outlined"
                  />
                </div>
                <Button
                  className={`${classes.blackBtn}`}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setPaymentType('cash');
                    patientContext.handleDialogClose();
                    displayCheckoutScreen(selectedDoctor, 'cash');
                  }}
                >
                  Pay Cash
                </Button>
              </Grid>
            </Grid>
          </div>
        ),
        maxWidth: 'sm',
        dialogAction: null,
      });
    } else {
      displayCheckoutScreen(selectedDoctor, paymentType);
    }
  };

  const displayCheckoutScreen = (sd, paymentType) => {
    setCurrentStep((oldValue) => oldValue + 1);
    // patientContext.dialogController({
    //   open: true,
    //   contentClass: "pb-3 px-3 text-break contentClasses",
    //   content: (
    //     <div>
    //       <CheckoutScreen
    //         selectedDoctor={sd[0]}
    //         paymentType={paymentType}
    //         appointmentDetails={{
    //           date: selectedDate,
    //           time: selectedTime.value
    //         }}
    //         patientProfile={patientContext.profileData}
    //         bookAppointmentCall={bookAppointmentCall}
    //         classes={classes}
    //         appointmentType={appointmentType}
    //       />
    //     </div>
    //   ),
    //   maxWidth: "xs",
    //   dialogAction: null,
    // });
  };

  const bookAppointmentCall = async (typeofPayment) => {

    setBookAppLoader(true);
    try {
      const url = `/doctor/appointment/${constants.hospitalId(
        'hosp_id'
      )}/${doctorId}`;
      const appointmentRes = await ServiceCall.post(
        url,
        {
          appointment_date: selectedDate,
          time_alloted: selectedTime.compareTime,
          is_inperson:
            appointmentType && appointmentType === 'inperson'
              ? 'True'
              : 'False',
          pay_cash: typeofPayment === 'cash' ? 'True' : 'False',
          end_time: selectedTime.end_time,
          slot_duration: selectedTime.slot_duration,
        },
        { pt_token: Ls.get('userToken') }
      );

      if (typeofPayment === 'online') {
        const fetchOrderUrl = `/order/appointment/${
          appointmentRes.data[constants.appointment_id]
        }`;
        const appointmentFetchRes = await ServiceCall.get(fetchOrderUrl, {
          pt_token: Ls.get('userToken'),
        });

        let doctorData = doctorList.filter((item) => {
          return item.doc_id === doctorId;
        });

        razorpayCall(
          appointmentFetchRes.data.message.razorpay_order_id,
          appointmentRes.data[constants.appointment_id],
          confirmPayment,
          doctorData,
          patientContext.profileData,
          patientContext.hospitalDetails
        );
      } else {
        setBookAppLoader(false);
        runAfterPayment(appointmentRes.data.message);
      }
    } catch (err) {
      // patientContext.getAppointment();
      // console.log(err);
      setBookAppLoader(false);
      toggleAppointment();
      if (err.response) {
        // setApiError('Failed with response ' + err.response.data);
        patientContext.dialogController({
          open: true,
          content: err.response.data.message,
          maxWidth: 'sm',
        });
      } else if (err.request) {
        // setApiError('Failed request ' + err);
        patientContext.dialogController({
          open: true,
          content: err,
          maxWidth: 'sm',
        });
      } else {
        // setApiError('Failed in general ' + err);
        patientContext.dialogController({
          open: true,
          content: err,
          maxWidth: 'sm',
        });
      }
    }
  };

  const confirmPayment = async (order_id, paymentObject, errorObject) => {
    if (errorObject) {
      if (errorObject.errorType === 'ondismiss') {
        setBookAppLoader(false);
        toggleAppointment();
      } else {
        patientContext.dialogController({
          open: true,
          content: errorObject.errorMessage,
          maxWidth: 'sm',
        });
        setBookAppLoader(false);
        runAfterPayment();
      }
    } else {
      let url = `/order/online/payment/${order_id}`;
      ServiceCall.post(url, paymentObject, {
        pt_token: Ls.get('userToken'),
      })
        .then((res) => {

          setBookAppLoader(false);
          runAfterPayment(res.data.message);
        })
        .catch((err) => {
          setBookAppLoader(false);
          if (err.response) {
            setApiError('Failed with response ' + err.response.data);
          } else if (err.request) {
            setApiError('Failed request ' + err);
          } else {
            setApiError('Failed in general ' + err);
          }
        });
    }
  };
  const runAfterPayment = function (displayMessage) {
    // console.log('run after payment');
    if (displayMessage) {
      patientContext.dialogController({
        open: true,
        content: <div className="text-center">{displayMessage}</div>,
        maxWidth: 'sm',
      });
    }
    patientContext.getAppointment();
    setCurrentStep(0);
    setSelectedDate(dayjs().format('YYYY-MM-DD'));
    toggleAppointment();
  };

  const changeAppointmentType = function (type) {
    setAppointmentType(type);
    setSelectedDate(dayjs().format('YYYY-MM-DD'));
    setCurrentStep((oldValue) => oldValue + 1);
  };
  const dateChange = function () {
    // if (followUp) {
    //   // setDoctorId(followUp.doc_id);
    //   getAvailableSlots(null, followUp.doc_id);
    // } else {
    setSelectedDoctor([]);
    getAvailableDoctor();
    // }
  };
  const getAvailableSlots = function (e, dId, followUp) {
    // /test/doctor/<str: hospital_id>/<str: doctor_id></str>
    let doctorId = e ? e.target.value : dId;

    setDoctorId(doctorId);
    let selectedDoc = doctorList.filter((item) => {
      return item.doc_id === doctorId;
    });
    setCurrentStep((oldValue) => oldValue + 1);

    setSelectedDoctor(selectedDoc);
    setAppointmentLoader('loading');
    resetStates();
    let url = `/doctor/${constants.hospitalId('hosp_id')}/${doctorId}/${
      appointmentType === 'online' ? 'online' : 'in-person'
    }/${dayjs(selectedDate).format('YYYY-MM-DD')}`;
    ServiceCall.get(url)
      .then((res) => {
        setDoctorLoader(false);
        // setDoctorList(res.data['Registered Doctors']);
        if (res.data.time_slots) {
          setAppointment(convertTimeSlot(res.data.time_slots));
        } else {
          setAppointment([]);
          setAppointMentSlotMsg('No Availability Found');
        }
        setAppointmentLoader('');
      })
      .catch((err) => {
        setAppointmentLoader('');
        setAppointMentSlotMsg('');
        if (err.response) {
          setApiError('Failed with response ' + err.response.data);
        } else if (err.request) {
          setApiError('Failed request ' + err);
        } else {
          setApiError('Failed in general ' + err);
        }
      });
  };
  const renderDateField = () => {
    let minRange = dayjs().format('YYYY-MM-DD');
    let maxRange = dayjs().add(7, 'day').format('YYYY-MM-DD');
    if (followUp) {
      minRange = dayjs().add(1, 'day').format('YYYY-MM-DD');
      maxRange = dayjs(followUp.followup_by).format('YYYY-MM-DD');
    }
    return (
      <TextField
        id="date"
        label="Date"
        className="w-100"
        type="date"
        InputProps={{
          inputProps: {
            min: minRange,
            max: maxRange,
            onKeyPress: (e) => {
              e.preventDefault();
            },
          },
        }}
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };
  useEffect(() => {
    if (currentStep === 0) {
      initialLoad.current = true;
    }
    if (currentStep === 2) {
      setDoctorId('');
    }
  }, [currentStep]);
  const changeAppointmentScreenTab = function (index) {
    if (index === 0) {
      setAppointmentType('');
    }
    setCurrentStep(index);
  };
  const renderDoctorList = () => {
    let doctorListClone = JSON.parse(JSON.stringify(doctorList));
    let docList = doctorListClone;
    if (followUp) {
      docList = doctorListClone.filter(
        (item) => item.doc_id === followUp.doc_id
      );
    }
    return docList.map((item, index) => (
      <Box className="cursor-pointer" key={item.doc_id}>
        <input
          type="radio"
          id={item.doc_id}
          checked={item.doc_id === doctorId}
          name="doctor_list"
          className={classes.radio}
          value={item.doc_id}
          onChange={getAvailableSlots}
        />
        <label
          className={`${classes.label} d-block cursor-pointer font-16`}
          key={item.doc_id}
          htmlFor={item.doc_id}
        >
          <Box
            className={`${classes.listItem} font-14`}
            sx={{
              py: 1,
              px: 1,
              display: 'flex',
              alignItems: 'center',
              borderRadius: 1,
              boxShadow: 2,
              mb: 2,
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={2} sm="auto">
                {/* <ImageAvatar
                  size="40px"
                  imagePath={`${baseUrl}${
                    constants.testPath('mode') !== 'test'
                      ? ''
                      : '/' + constants.testPath('mode')
                  }/doctor/avatar/${item.doc_id}`}
                  name={`${item.firstname} ${item.lastname}`}
                /> */}
              </Grid>
              <Grid container item xs={10} className="top">
                <Grid item xs={12} sm={3} className="">
                  <h4 className="my-0 pb-2 pb-sm-0">
                    {item.firstname} {item.lastname}
                  </h4>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {item.qualification}, {item.speciality}
                </Grid>
                {!followUp && (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="text-sm-center pt-2 pt-sm-0"
                  >
                    {item.currency} {item.consult_charge}
                  </Grid>
                )}
              </Grid>
              {!followUp && (
                <Grid item xs={12} className="text-center">
                  {item.online_discount > 0 && (
                    <Chip
                      className={`${classes.blackBtn}`}
                      variant="filled"
                      label={`Get ${item.online_discount}% discount on online payment.`}
                      color="success"
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </label>
      </Box>
    ));
  };

  function TopScreenBar() {
    if (currentStep !== 4) {
      return (
        <Box
          sx={{
            alignItems: 'center',
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '14px',
            fontWeight: '600',
            textTransform: 'capitalize',
          }}
          className=" mb-4"
        >
          <div>
            {appointmentType !== '' && currentStep !== 1 ? (
              appointmentType == 'online' ? (
                <p className="d-flex align-items-center">
                  <VideoCameraFrontIcon
                    sx={{ fontSize: 30, marginRight: '6px' }}
                    color="error"
                  />{' '}
                  <span>{appointmentType} Appointment</span>
                </p>
              ) : (
                <p className="d-flex align-items-center">
                  <AddBusinessIcon
                    sx={{ fontSize: 30, marginRight: '6px' }}
                    color="error"
                  />{' '}
                  <span>{appointmentType} Appointment</span>
                </p>
              )
            ) : (
              <></>
            )}
          </div>
          <div>
            {currentStep == 0 && (
              <p className="text-center">Select Appointment Type</p>
            )}
            {currentStep == 1 && (
              <p className="d-flex align-items-center justify-content-center">
                Select Date For{' '}
                {appointmentType == 'online' ? (
                  <>
                    <VideoCameraFrontIcon
                      sx={{ fontSize: 30, margin: '0 6px' }}
                      color="error"
                    />{' '}
                    <span>{appointmentType} Appointment</span>
                  </>
                ) : (
                  <>
                    <AddBusinessIcon
                      sx={{ fontSize: 30, margin: '0 6px' }}
                      color="error"
                    />{' '}
                    <span>{appointmentType} Appointment</span>
                  </>
                )}
              </p>
            )}
            {currentStep == 2 && (
              <p className="text-center">
                Select from available doctors as on{' '}
                <span style={{ fontSize: '16px' }}>
                  {patientContext.dateFormate(selectedDate)}
                </span>
              </p>
            )}
            {currentStep == 3 && (
              <p className="text-center">
                Select preferred slot from{' '}
                <span style={{ fontSize: '16px' }}>
                  {selectedDoctor[0].firstname}
                  {selectedDoctor[0].lastname}
                </span>{' '}
                schedule on{' '}
                <span style={{ fontSize: '16px' }}>
                  {patientContext.dateFormate(selectedDate)}
                </span>
              </p>
            )}
          </div>
        </Box>
      );
    }
    return <></>;
  }

  return (
    <Grid
      component={Box}
      container
      justifyContent="center"
      className="position-relative w-100 pt-0"
    >
      <Box
        component={Paper}
        sx={{ py: 5, px: 3, maxWidth: '400px', position: 'relative' }}
      >
        <IconButton
          onClick={toggleAppointment}
          color="error"
          sx={{ position: 'absolute', right: '0', top: '4px' }}
          component="span"
        >
          <HighlightOffIcon />
        </IconButton>
        <Grid
          container
          spacing={0}
          className={`justify-content-center w-100 stepper pb-3 selected-${currentStep} ${
            followUp ? 'followUpSteps' : ''
          }`}
        >
          {steps.map((item, index) => {
            return (
              <Grid
                key={item + index}
                xs={5}
                className="text-center"
                item
                sx={{
                  maxWidth: 100 / steps.length + '%',
                  width: 100 / steps.length + '%',
                  flexGrow: 100 / steps.length + '%',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Button
                  className="px-1 px-2"
                  disabled={currentStep < index || bookAppLoader}
                  onClick={() => changeAppointmentScreenTab(index)}
                  key={index + item}
                  variant="contained"
                  sx={{
                    minWidth: 34,
                    boxShadow: 'none',
                    padding: '2px',
                    borderRadius: '50%',
                    border: '2px solid #ffffff',
                  }}
                  label={index + 1}
                >
                  <Box>{index + 1}</Box>
                </Button>
                <Box className="font-11 px-1">{item}</Box>
              </Grid>
            );
          })}
        </Grid>
        <TopScreenBar />
        {/* //appointment type select start */}
        {currentStep === 0 && (
          <Grid
            item
            xs={12}
            className="position-relative w-100 pt-2"
            justifyContent="center"
          >
            {/* inperson selection start */}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
              className="font-13"
            >
              {/* <Box className='w-100 text-center'>
                <h3 className='mb-2 mt-3'>1. Choose Appointment Type</h3>
              </Box> */}
              <Box className="px-3" sx={{ pb: 3 }}>
                <div className="w-100 text-center">
                  <VideoCameraFrontIcon sx={{ fontSize: 30 }} color="error" />
                </div>
                <div className="d-flex justify-content-center">
                  <input
                    type="radio"
                    name="inpersonoronline"
                    className={classes.radioTime}
                    checked={appointmentType === 'online'}
                    id="onlinePayment"
                    value={appointmentType}
                    onChange={() => changeAppointmentType('online')}
                  />

                  <label htmlFor="onlinePayment" className="d-inline ">
                    <Box
                      className={`${classes.timeButton} mx-sm-2 mx-1`}
                      sx={{
                        px: 1,
                        py: 1,
                        mb: 1,
                        borderRadius: 1,
                      }}
                    >
                      Online
                    </Box>
                  </label>
                </div>
                <span className="d-block font-10 pb-3 pb-sm-0">
                  Click to book online or video consultation.
                </span>
              </Box>
              <Box className="px-3">
                <div className="w-100 text-center">
                  <AddBusinessIcon sx={{ fontSize: 30 }} color="error" />
                </div>
                <div className="d-flex justify-content-center">
                  <input
                    type="radio"
                    name="inpersonoronline"
                    className={classes.radioTime}
                    checked={appointmentType === 'inperson'}
                    id="inpersonPayment"
                    value={appointmentType}
                    onChange={() => changeAppointmentType('inperson')}
                  />
                  <label htmlFor="inpersonPayment" className="d-inline ">
                    <Box
                      className={`${classes.timeButton} mx-sm-2 mx-1`}
                      sx={{
                        px: 1,
                        py: 1,
                        mb: 1,
                        borderRadius: 1,
                      }}
                    >
                      In person
                    </Box>
                  </label>
                </div>
                <span className="d-block font-10  pb-3 pb-sm-0">
                  Click to book in-person visit at the clinic or hospital.
                  <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <div>
                      <LocationOnIcon color="success" />
                    </div>
                    <div> {patientContext.hospitalDetails.address}</div>
                  </div>
                </span>
              </Box>
            </Box>
          </Grid>
        )}
        {/* appointment type select end */}

        {/* appointment date select start */}
        {currentStep === 1 && (
          <Grid item xs={12}>
            {/* <Box className='w-100 text-center'>
              <h3 className='mb-2 mt-3'>2. Select Appointment Date
              </h3>
            </Box> */}
            {renderDateField()}
            <Box sx={{ textAlign: 'right' }}>
              <Button onClick={dateChange}>Next</Button>
            </Box>

            {doctorLoader ? (
              <Box
                sx={{ textAlign: 'center' }}
                className="d-block pl-3 text-center"
              >
                <CircularProgress size={14} />
              </Box>
            ) : (
              !initialLoad.current &&
              doctorList.length === 0 && (
                <Box className="text-center">
                  <h4>
                    No doctors available at this date. Please select another
                    date.
                  </h4>
                </Box>
              )
            )}
          </Grid>
        )}
        {/* appointment date select end */}

        {/* available doctor screen start */}
        {currentStep === 2 && (
          <Grid item xs={12}>
            {/* <Box className='w-100 text-center'>
              <h3 className='mb-2 mt-3'>3. Select Doctors</h3>
            </Box> */}

            {renderDoctorList()}
          </Grid>
        )}
        {/* available doctor screen end */}
        {/* select time slots start */}
        {currentStep === 3 && (
          <Grid item xs={12}>
            {/* <Box className='w-100 text-center'>
              <h3 className='mb-2 mt-3'>4. Select Doctor’s Time Slot</h3>
            </Box> */}
            {appointmentLoader === 'loading' ? (
              <Box
                sx={{ textAlign: 'center' }}
                className="d-block pl-3 text-center"
              >
                <CircularProgress size={14} />
              </Box>
            ) : appointments.length > 0 ? (
              <Grid container spacing="1">
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={11.3}
                      minRange={0}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: 'grey.300',
                          color: 'grey.900',
                          p: 1,
                          mb: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ width: '23px' }}>
                          <img
                            alt=""
                            style={{
                              width: '100%',
                              height: '100%',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            }}
                            src={`${process.env.PUBLIC_URL}/img/morning-sun.png`}
                          />
                        </div>{' '}
                        Morning
                      </Box>
                    </TimeSelectedScreen>
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={17}
                      minRange={12}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: 'grey.300',
                          color: 'grey.900',
                          p: 1,
                          mb: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <WbSunnyOutlinedIcon /> Afternoon
                      </Box>
                    </TimeSelectedScreen>
                    <TimeSelectedScreen
                      classes={classes}
                      appointments={appointments}
                      handleChange={handleChange}
                      maxRange={23.3}
                      minRange={17.3}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      bookAppLoader={bookAppLoader}
                    >
                      <Box
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        sx={{
                          bgcolor: 'grey.300',
                          color: 'grey.900',
                          p: 1,
                          mb: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Brightness2OutlinedIcon /> Evening
                      </Box>
                    </TimeSelectedScreen>
                  </FormControl>

                  <Box className="text-right">
                    {followUp && selectedTime.compareTime && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={callBookFollowUp}
                        size="small"
                        disabled={bookAppLoader}
                      >
                        Book Now
                        {bookAppLoader && <CircularProgress size={14} />}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <p className="text-center">{appointMentSlotMsg}</p>
            )}
          </Grid>
        )}
        {/* select time slots end */}
        {currentStep === 4 && (
          <Grid item>
            {/* <Box className='w-100 text-center'>
              <h3 className='mb-2 mt-3'>5. Checkout & Pay</h3>
            </Box> */}
            <CheckoutScreen
              selectedDoctor={selectedDoctor[0]}
              paymentType={paymentType}
              appointmentDetails={{
                date: selectedDate,
                time: selectedTime.slotformated,
              }}
              patientProfile={patientContext.profileData}
              bookAppointmentCall={bookAppointmentCall}
              classes={classes}
              appointmentType={appointmentType}
              bookAppLoader={bookAppLoader}
            />
          </Grid>
        )}
      </Box>
    </Grid>
  );
}
