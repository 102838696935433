import React from 'react';

const MemoMaleIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 34 34"
    >
      <circle cx="17" cy="17" r="17" fill={color || '#E8F3F1'}></circle>
      <circle
        cx="4.514"
        cy="4.514"
        r="4.014"
        stroke={!!color ? '#FFF' : '#009DFE'}
        transform="matrix(-.70922 -.70499 .70922 -.70499 14 25)"
      ></circle>
      <rect
        width="1.003"
        height="7.021"
        fill={!!color ? '#FFF' : '#009DFE'}
        rx="0.502"
        transform="scale(-1.00299 -.997) rotate(45 11.598 -28.555)"
      ></rect>
      <rect
        width="0.877"
        height="3.636"
        fill={!!color ? '#FFF' : '#009DFE'}
        rx="0.439"
        transform="matrix(-.2596 -.96303 .96881 -.25804 18.191 12.725)"
      ></rect>
      <rect
        width="0.89"
        height="3.621"
        fill={!!color ? '#FFF' : '#009DFE'}
        rx="0.445"
        transform="matrix(.96881 .25804 -.2596 .96303 20.89 10.963)"
      ></rect>
    </svg>
  );
};

export default MemoMaleIcon;
