import { green, red } from "@mui/material/colors";
import { hex2rgba } from "../utility/utility";

const appointmentScreensStyle = (theme) => {
  return {
    listItem: {
      '&:hover': {
        backgroundColor: hex2rgba(theme.palette.primary.main, 0.2),
      },
    },
    listHover: {
      backgroundColor: green[50],
    },
    label: {
      borderColor: 'green',
      // backgroundColor: 'green',
      // borderRadius: theme.borderRadius(1),
      // borderRadius: theme.borderRadius;
    },
    radioTime: {
      visibility: 'hidden',
      position: 'absolute',
      left: '-9999px',
      '&:checked + label': {
        '& >div': {
          backgroundColor: `#087E73`,
          color: '#ffffff',
        },
      },
      '&:checked:disabled + label': {
        '& >div': {
          backgroundColor: '#858985',
          color: '#ffffff',
        },
      },
      '&:disabled + label': {
        '& >div': {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[500],
          border: `1px solid ${theme.palette.grey[500]}`,
        },
      },
    },
    timeButton: {
      fontFamily: 'Inter !important',
      fontWeight: '500 !important',
      fontSize: '12px !important',
      minWidth:'56px',
      textAlign:'center',
      padding:'0.4rem 0.8rem !important',
      border: `1px solid #087E73`,
      color: '#929292',
      transition: 'all 0.2s linear',
      cursor: 'pointer',
      backgroundColor: '#E8F3F1',
      '&:hover': {
        border: `1px solid #087E73`,
        color: 'white',
        backgroundColor: '#087E73',
      },
    },
    radio: {
      visibility: 'hidden',
      position: 'absolute',
      left: '-9999px',

      '&:checked + label': {
        '& >div': {
          backgroundColor: green[50],
          // color: '#ffffff',
        },
      },
    },
    headerBg: {
      backgroundColor: theme.palette.grey[600],
      color: '#000000',
    },
    input: {
      display: 'none',
    },
    stackStyle: {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    danger: {
      color: red[800],
    },
    apiProgress: {
      height: '2px !important',
      '& span': {},
    },
    blackBtn: {
      backgroundColor: '#212121 !important',
      color: '#ffffff !important',
      '&.MuiChip-outlined': {
        backgroundColor: 'transparent !important',
        border: '1px solid #212121 !important',
        color: '#212121 !important',
      },
    },
  };
};
export default appointmentScreensStyle;
