import login1 from './images/carousel1.png';
import login2 from './images/login_carousel_2.png';
import login3 from './images/login_carousel_3.png';
import profileName from "./images/profile_name.svg"
import profileMail from "./images/profile_mail.svg"
import profileCalendar from "./images/calendar-icon.svg"
import profileAge from "./images/profile_age.svg";
import NoAppointmentfound from "./images/no-appointment.png"
export const LoginImage = [
  {
    image: login1,
    text: 'Connect with your trusted doctor',
  },
  {
    image: login2,
    text: 'Intelligent insights of your health',
  },
  {
    image: login3,
    text: 'Free Care Tools',
  },
];

export const profileIcon = {
  name: profileName,
  mail: profileMail,
  calendar: profileCalendar,
  age: profileAge,
};

export const AppointmentImages = {
  noAppointmentIcon: NoAppointmentfound,
};