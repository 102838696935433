import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from '@material-ui/core';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { PatientpageContext } from './../context/GlobalContext';

import * as dayjs from 'dayjs';
var duration = require('dayjs/plugin/duration');

dayjs.extend(duration);
export default function JoinButton({ item, indexNo, joinLogic }) {
  // const [timeDif, setTimeDif] = useState(0);
  // const patientContext = useContext(PatientpageContext);
  const isMountedRef = useRef(null);
  // const timer = setTimeout(() => {
  //   if (isMountedRef.current) {
  //     setTimeDif((oldVal) => oldVal + 1);
  //   }
  // }, 1000);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      // clearTimeout(timer);
    };
  }, []);

  const joinPage = () => {
    joinLogic();
  };
  const isDisabled = () => {
    if (item.sameDay === 'today') {
      return false;
    }
    return true;
  };
  return (
    <Button
      variant="contained"
      color="error"
      size="small"
      // target="_blank"
      // href={item.room_url}
      // to={`/join/${item.room_id}`}
      onClick={joinPage}
      className={isDisabled() ? '' : 'btn-success'}
      // className="btn-success"
      disabled={isDisabled()}
      // component={Link}
      startIcon={<VideoCameraFrontIcon />}
    >
      Join
    </Button>
  );
}
