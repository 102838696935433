import React,{useContext, useEffect,useState} from 'react'
import FreshChatApp from '../utility/FreshChatApp'
import { PatientpageContext } from '../context/GlobalContext'

function VerifyBot() {
    const [user ,setUser] = useState(false);
    const patientContext = useContext(PatientpageContext);
    const userToken = localStorage.getItem("userToken")
    const patient =patientContext.profileData;

    useEffect(()=>{
        if(userToken !== null){
            setUser(true)
        }
    },[userToken])


    return  (
        <>
        {user && <FreshChatApp/>}
        </>
    )
   
}

export default VerifyBot